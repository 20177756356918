import React, { useEffect, useState } from 'react';
import { Steps, Button, Form, Select, Modal, Row, Col, Input } from 'antd';
import { Card, Typography, Divider } from 'antd';
import { StepReview } from './StepReview';
import { StepEmployee } from './StepEmployee';
import { StepSelectLawyer } from './StepSelectLawyer';
import { StepDetails } from './StepDetails';
import { getAllUser, getCaseDetails, getRejectedReasonList, updateCaseStatusByAdmin } from '../../../Utils/ApiManager';
import { Spinner } from '../../../components/Spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { StepPayments } from './StepPayments';
import { toast } from 'react-toastify';
import { toastMessage } from '../../../Utils/Utils';
import { useSelector } from 'react-redux';

const { Text, Title } = Typography;

const { Step } = Steps;
const { Option } = Select;

export const NewCaseDetails = ({ newCaseModelTitle }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isConfirmationModel, setIsConfirmationModel] = useState(null);
    const [finalConfirmation, setFinalConfirmation] = useState(null);
    const [caseRejected, setIsCaseRejected] = useState(null);

    const [primaryLawyerDetails, setPrimaryLawyerDetails] = useState(null);
    const [redundentLawyerDetails, setRedundentLawyerDetails] = useState(null);
    const [lawyerList, setLawyerList] = useState(null);
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [employeeList, setEmployeeList] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [caseDetails, setCaseDetails] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [rejectedReason, setRejectedReason] = useState("");
    const [rejectedReasonList, setRejectedReasonList] = useState([]);
    const [rejectedComment, setRejectedComment] = useState("");

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const caseId = searchParams.get('caseId');
    const navigate = useNavigate()
    const userData = useSelector(state => state.UserData)

    useEffect(() => {
        if (caseId) {
            getCaseDetailData()
        }
        getLawyerList()
        getEmployeeList()
        getReasonList()
    }, [])

    useEffect(() => {
        if (caseRejected) {
            getReasonList()
        }
    }, [caseRejected])


    const getReasonList = async () => {
        setIsLoading(true)
        try {
            const response = await getRejectedReasonList()
            setRejectedReasonList(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    const getCaseDetailData = async () => {
        setIsLoading(true)
        try {
            const response = await getCaseDetails(`?CaseId=${caseId}`)
            setCaseDetails(response?.data?.data?.caseDetails)
            setUserDetails(response?.data?.data?.citizenDetail)
            setPrimaryLawyerDetails(response?.data?.data?.lawyerDetail)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }


    const getLawyerList = async () => {
        setIsLoading(true)
        try {
            const response = await getAllUser(`?IsPending=${false}&RoleId=${4}`)
            setLawyerList(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    const getEmployeeList = async () => {
        setIsLoading(true)
        try {
            const response = await getAllUser(`?IsPending=${false}&RoleId=${5}`)
            setEmployeeList(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleSubmit = async () => {
        setIsLoading(true)
        try {
            const caseDetailsObject = {
                "userId": userData?.userId,
                "citizenId": userDetails?.userId,
                "caseId": caseDetails?.caseId,
                "employeeId": employeeDetails?.userId,
                "lawyerId": primaryLawyerDetails?.userId,
                "redundantLawyerId": redundentLawyerDetails?.userId,
                "caseStatus": !caseRejected,
                "paymentStatusId": 0,
                "rejectionId": rejectedReason ? rejectedReason : null,
                "rejectionNote": rejectedComment
            }
            await updateCaseStatusByAdmin(caseDetailsObject)
            setIsLoading(false)
            toastMessage("Case updated successfully", true)
            navigate("/newcases")
        } catch (error) {
            toastMessage("Error while updating case request",)
            setIsLoading(false)
        }
    };



    const steps = [
        {
            id: "CASEDETAILS",
            title: 'Case Details',
            content: <StepDetails caseDetails={caseDetails} userDetails={userDetails || {}} />
        },
        {
            id: "SELECTLAWYER",
            title: 'Select Lawyer',
            content: <StepSelectLawyer
                lawyerList={lawyerList}
                primaryLawyerDetails={primaryLawyerDetails}
                setPrimaryLawyerDetails={setPrimaryLawyerDetails}
                redundentLawyerDetails={redundentLawyerDetails}
                setRedundentLawyerDetails={setRedundentLawyerDetails}

            />
        },
        {
            id: "SELECTEMPLOYEE",
            title: 'Select Employee',
            content: <StepEmployee
                employeeList={employeeList}
                employeeDetails={employeeDetails}
                setEmployeeDetails={setEmployeeDetails} />
        },
        {
            id: "PAYMENT",
            title: 'Payment Details',
            content: <StepPayments />
        },
        {
            id: "REWIEW",
            title: 'Review',
            content: <StepReview
                caseDetails={caseDetails}
                userDetails={userDetails || {}}
                primaryLawyerDetails={primaryLawyerDetails}
                redundentLawyerDetails={redundentLawyerDetails}
                employeeDetails={employeeDetails}
            />
        },

    ];

    const confirmationRequest = () => {
        return <Modal
            title="Request Confirmation"
            visible={isConfirmationModel}
            onCancel={() => {
                setIsConfirmationModel(false)
                setFinalConfirmation(false)
                setRejectedComment("")
                setRejectedReason("")
            }}
            footer={[
                <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
                    <Button
                        className='me-2'
                        style={{ width: '120px' }} key="back" onClick={() => {
                            if (caseRejected) {
                                handleSubmit()
                            } else {
                                setIsCaseRejected(true)
                            }
                        }}>
                        Reject
                    </Button>
                    {
                        !caseRejected &&
                        <Button style={{ width: '120px' }} key="submit" type="primary" onClick={() => setFinalConfirmation(true)}>
                            Accept
                        </Button>
                    }

                </div>,
            ]}
        >
            <div  >
                {caseRejected ? (
                    <Card
                        style={{
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            borderRadius: '8px',
                            marginTop: '10px'
                        }}
                    >
                        <Form layout="vertical">
                            <Form.Item label="Reject Reason">
                                <Select value={rejectedReason} onChange={value => setRejectedReason(value)} style={{ width: '100%' }}>
                                    {rejectedReasonList?.map(reason => (
                                        <Option key={reason.rejectionId} value={reason.rejectionId}>{reason.description}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Comments">
                                <Input.TextArea value={rejectedComment} onChange={e => setRejectedComment(e.target.value)} />
                            </Form.Item>
                        </Form>
                    </Card>
                )
                    :
                    <h6 className=''>Do you want to accept or reject this request?</h6>
                }
            </div>
        </Modal>
    }

    const finalConfirmationRequest = () => {
        return <Modal
            title="Request Confirmation"
            visible={finalConfirmation}
            onCancel={() => setFinalConfirmation(false)}
            footer={[
                <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
                    <Button
                        style={{ width: '120px' }}
                        key="back"
                        onClick={() => {
                            setFinalConfirmation(false)
                            setIsCaseRejected(false)
                            setRejectedComment("")
                            setRejectedReason("")

                        }}>
                        No
                    </Button>,
                    <Button style={{ width: '120px' }} key="submit" type="primary" onClick={handleSubmit}>
                        Yes
                    </Button>
                </div>,
            ]}
        >
            <div  >
                <h6 className=''>Do you really want to accept this request?</h6>
            </div>
        </Modal >
    }

    return (
        <div>
            {
                isLoading && <Spinner />
            }
            {
                finalConfirmationRequest()
            }
            {
                confirmationRequest()
            }
            <div className='d-flex' style={{ justifyContent: 'center', width: '100%' }}>
                <Title className='mb-5' level={4} style={{}}>{newCaseModelTitle || "Accept/Reject Case Request"}</Title>
            </div>
            <Row gutter={[16, 16]} >
                <Col xs={24} sm={24} md={24} lg={3} xl={3}></Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                    <Steps current={currentStep} className='mb-5'>
                        {steps.map((item) => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div className="steps-content ">{steps[currentStep].content}</div>
                    <Divider />
                    <div className="steps-action" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {(
                            <Button style={{ margin: '0 8px' }} onClick={handlePrevious}
                                disabled={currentStep === 0}>
                                Previous
                            </Button>
                        )}
                        {currentStep === 4 ?
                            <Button type="primary" onClick={() => {
                                setIsConfirmationModel(true)
                                setIsCaseRejected(false)
                            }}>
                                Fininsh
                            </Button>
                            :
                            <Button type="primary" onClick={handleNext}
                                disabled={currentStep > steps.length - 1}>
                                Next
                            </Button>
                        }
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={3} xl={3}></Col>
            </Row>

        </div>
    );
};

