import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Select, Button } from 'antd';
import { getRoles, registerAccount } from '../../Utils/ApiManager';
import { Spinner } from '../../components/Spinner';
import { toast } from 'react-toastify';

const { Item } = Form;
const { Option } = Select;

export const AddUser = ({ selectedRecord, setIsAddModalVisible, role }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [userRoles, setUserRoles] = useState([])
    const [form] = Form.useForm();

    useEffect(() => {
        getUserRoles()
    }, [])
    console.log("selectedRecord", selectedRecord)
    useEffect(() => {
        if (selectedRecord) {
            if (selectedRecord && Object.keys(selectedRecord).length > 0) {
                form.setFieldsValue({
                    FirstName: selectedRecord.firstName || '',
                    LastName: selectedRecord.lastName || '',
                    Email: selectedRecord.email || '',
                    PhoneNumber: selectedRecord.contactNumber || '',
                    "countryCode": selectedRecord.countryCode || '',
                    UserName: selectedRecord.UserName || '',
                    IsOverseas: selectedRecord.isOverseas || false,
                });
            }
        }
    }, [selectedRecord])

    const getUserRoles = async () => {
        setIsLoading(true)
        try {
            const response = await getRoles()
            if (response?.data?.data) {
                setUserRoles(response?.data?.data)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }


    const handleAddModalOk = async (values) => {
        setIsLoading(true);
        try {
            const payload = {
                RoleName: ["Citizen", "Lawyer"].includes(role) ? role : values.RoleName,
                LastName: values.LastName || "",
                IsOverseas: values.IsOverseas || false,
                UserName: values.UserName,
                PhoneNumber: values.PhoneNumber,
                "countryCode": "+92",
                FirstName: values.FirstName,
                Password: values.Password,
                Email: values.Email,
            };

            await registerAccount(payload);

            console.log('Add User:', values);
            setIsAddModalVisible(false);
            toast.success("Record registered successfully");
        } catch (error) {
            setIsAddModalVisible(false);
            toast.error(error?.message);
            console.log('Failed:', error);
        } finally {
            setIsLoading(false);
        }
    };



    const filteredRoles = role !== 'User'
        ? userRoles?.filter(role => role.name !== "Admin" && role.name !== "Lawyer" && role.name !== "Citizen")
        : [];

    return (
        <>
            {isLoading && <Spinner />}
            <Form form={form} initialValues={selectedRecord} layout="vertical" className='pt-4'
                onFinish={handleAddModalOk}>
                <Row gutter={[16, 16]}>
                    {/* <Col xs={24} sm={12}>
                        <Item
                            name="userId"
                            label="UserId"
                        >
                            <Input placeholder="Enter UserId" />
                        </Item>
                    </Col> */}
                    <Col xs={24} sm={12}>
                        <Item
                            name="FirstName"
                            label="First Name"
                            rules={[{ required: true, message: 'Please enter FirstName' }]}
                        >
                            <Input placeholder="Enter FirstName" />
                        </Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Item
                            name="LastName"
                            label="Last Name"
                            rules={[{ required: true, message: 'Please enter LastName' }]}
                        >
                            <Input placeholder="Enter LastName" />
                        </Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Item
                            name="Email"
                            label="Email"
                            rules={[
                                { required: true, message: 'Please enter Email' },
                                { type: 'email', message: 'Please enter a valid Email' },
                            ]}
                        >
                            <Input placeholder="Enter Email" />
                        </Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Item
                            name="Password"
                            label="Password"
                            rules={[{ required: true, message: 'Please enter Password' }]}
                        >
                            <Input.Password placeholder="Enter Password" />
                        </Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Item
                            name="confirmPassword"
                            label="Confirm Password"
                            dependencies={['Password']}
                            rules={[
                                { required: true, message: 'Please confirm Password' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('Password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords do not match'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="Confirm Password" />
                        </Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Item
                            name="UserName"
                            label="User Name"
                            rules={[{ required: true, message: 'Please enter UserName' }]}
                        >
                            <Input placeholder="Enter UserName" />
                        </Item>
                    </Col>
                    {
                        ["Citizen", "Lawyer"].includes(role) ? "" :
                            <Col xs={24} sm={12}>
                                <Item
                                    name="RoleName"
                                    label="Role Name"
                                    rules={[{ required: true, message: 'Please select RoleName' }]}
                                >
                                    <Select placeholder="Select RoleName">
                                        {filteredRoles?.map(role => {
                                            return <Option value={role.name}>{role.name}</Option>
                                        })}
                                    </Select>
                                </Item>
                            </Col>
                    }

                    <Col xs={24} sm={12}>
                        <Item
                            name="PhoneNumber"
                            label="Phone Number"
                            rules={[{ required: true, message: 'Please enter PhoneNumber' }]}
                        >
                            <Input placeholder="Enter PhoneNumber" />
                        </Item>
                    </Col>

                    <Col xs={24} sm={12}
                    >
                        <Item
                            name="IsOverseas"
                            label="Is Overseas"
                            valuePropName="checked"
                        >
                            <Input
                                style={{ width: '20px' }}
                                defaultChecked={false}
                                type="checkbox" />
                        </Item>
                    </Col>
                </Row>

                <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <Button style={{ width: '150px', marginRight: '20px' }} type='default' onClick={() => setIsAddModalVisible(false)} >Close</Button>
                    <Button style={{ width: '150px', }} type='primary' htmlType='submit' >Save</Button>

                </div>
            </Form>
        </>
    );
};

export default AddUser;
