export const loggedIn = (payload) => {
  return {
    type: "LOGGED_IN",
    payload: payload,
  };
};

export const loginToken = (payload) => {
  return {
    type: "LOGGED_TOKEN",
    payload: payload,
  };
};

export const userData = (payload) => {
  return {
    type: "USER_DATA",
    payload: payload,
  };
};

export const utilsData = (payload) => {
  return {
    type: "UTILS_DATA",
    payload: payload,
  };
};

export const caseWithDateDetails = (payload) => {
  return {
    type: "CASEWITHDATEDETAILS",
    payload: payload,
  };
};

export const resetCaseWithDateDetails = (payload) => {
  return {
    type: "CASEWITHDATEDETAILS",
    payload: null,
  };
};
