import React, { useEffect, useState } from 'react'
import MyTable from '../../components/MyTable';
import { getCases, } from '../../Utils/ApiManager';
import { Button, Tabs } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


const { TabPane } = Tabs;

export const AllUsersReport = () => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [activeTab, setActiveTab] = useState('allZoneCases');

    const navigate = useNavigate()

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    useEffect(() => {
        getData()
    }, [activeTab])

    const getData = async () => {
        setIsLoading(true)

        try {
            const response = await getCases()
            setData(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    const handleViewDetails = (record) => {
        navigate(`/newcases/details?caseId=${record.id}`)
    };

    const tableColumns = [
        {
            title: 'Title',
            dataIndex: 'caseDateTitle',
            key: 'caseDateTitle',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Case Id',
            dataIndex: 'caseId',
            key: 'caseId',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Update Date',
            dataIndex: 'updateDate',
            key: 'updateDate',
            render: (updateDate) => (
                <span>{moment(updateDate).local().format('LLL')}</span>
            ), // Format date using Moment.js
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (createdDate) => (
                <span>{moment(createdDate).local().format('LLL')}</span>
            ), // Format date using Moment.js
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
        },
        {
            title: 'Is Deleted',
            dataIndex: 'isDeleted',
            key: 'isDeleted',
            render: (isDeleted) => (isDeleted ? 'Yes' : 'No'),
        },

        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: (_, record) => (
        //         <div size="middle">
        //             <Button
        //                 type='primary'
        //                 className='me-2'>
        //                 View Details
        //             </Button>
        //         </div>
        //     ),
        // },
    ];

    return (
        <div>
            <MyTable

                componentHeader={
                    <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <h4>All Users Report</h4>
                        </div>
                        <Tabs activeKey={activeTab} onChange={handleTabChange}>
                            <TabPane tab="All Users Report" key="allZoneCases">
                            </TabPane>
                        </Tabs>
                    </>
                }
                columns={tableColumns}
                data={data}
                isLoading={isLoading}
            />

        </div>
    );
};
