import React, { useEffect } from 'react'
import { Row, Col, Avatar, List, Card, Menu, Dropdown, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useState } from 'react';
import moment from 'moment';

export const ChatUserList = ({ selectedUserChat, handleSelectedUserChat, myChats }) => {
    const [searchText, setSearchText] = useState('');

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
    };




    const filteredAndSearchedMessages = myChats.filter((chat) =>
        chat?.fullName?.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <Card className=''>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className='d-flex mb-3' style={{ alignItems: 'center', width: '100%' }}>
                    <Input placeholder='Search... ' onChange={handleSearchTextChange} value={searchText} />
                    <span className="search-icon ms-2">
                        <SearchOutlined style={{ fontSize: '20px' }} />
                    </span>
                </div>
            </div>
            <List
                dataSource={filteredAndSearchedMessages}
                renderItem={(item) => (
                    <List.Item
                        style={{
                            background: selectedUserChat?.userId === item?.userId ? "#e79c1b36" : "",
                            cursor: 'pointer',
                            borderRadius: '10px',
                            padding: '10px'
                        }}
                        className={selectedUserChat?.userId === item?.userId ? 'selected-message' : ''}
                        onClick={() => handleSelectedUserChat(item)}
                    >
                        <List.Item.Meta
                            avatar={<Avatar size={60} src={item?.imageUrl} />}
                            title={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>{item?.fullName}</span>
                                    {!item?.isRead && (
                                        <div style={{
                                            width: '5px',
                                            height: '5px',
                                            borderRadius: '50%',
                                            background: 'orange',
                                            marginLeft: '5px' // Add some spacing between the name and the dot
                                        }}></div>
                                    )}
                                </div>
                            }
                            description={
                                <div>
                                    <div>{item?.content.length > 25 ? `${item?.content.substring(0, 25)}...` : item?.content}</div>
                                    <p style={{ fontSize: '12px' }}>{moment(item?.createdDate).local().format("lll")}</p>
                                    {/* <div><RoundTextTag value={item?.isRead ? "seen" : ""} bgColor={"#00979C26"} textColor={"#00979C"} /> {item?.createdDate}</div> */}
                                </div>
                            }
                        />
                    </List.Item>

                )}
            />

        </Card>
    )
}
