import React from 'react';
import { Table } from 'antd';

const MyTable = ({ columns, data, componentHeader, isLoading }) => {
    return <>
        {componentHeader}
        <Table size="small" columns={columns} dataSource={data} loading={isLoading} />
    </>
};

export default MyTable;
