import React, { useState } from 'react';
import { Steps, Button, Form, Select, Modal, Row, Col, Input, DatePicker } from 'antd';
import { Card, Typography, Divider } from 'antd';
import moment from 'moment';

const { Text, Title } = Typography;

const { Step } = Steps;
const { Option } = Select;

export const StepDetails = ({
    caseDetails,
    setCaseDetails,
    userDetails
}) => {
    const [isEditing, setIsEditing] = useState(false);

    const handleInputChange = (field, value) => {
        setCaseDetails({
            ...caseDetails,
            [field]: value,
        });
    };

    const onEdit = () => {
        setIsEditing(true);
    };

    const onSave = () => {
        setIsEditing(false);
    };

    const onCancel = () => {
        setIsEditing(false);
    };

    return (
        <>
            <Row gutter={[16, 16]} justify="space-between">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card
                        style={{
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            borderRadius: '8px',
                            padding: '10px',
                        }}
                    >
                        <Title level={5}>Case Details</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>ID:</Text>
                                    {isEditing ? (
                                        <Input
                                            disabled
                                            style={{ marginBottom: '10px' }}
                                            value={caseDetails?.caseId}
                                            onChange={(e) => handleInputChange('caseId', e.target.value)}
                                        />
                                    ) : (
                                        <Text strong className='ms-1'>{caseDetails?.caseId}</Text>
                                    )}
                                </div>
                                <div>
                                    <Text>Title:</Text>
                                    {isEditing ? (
                                        <Input
                                            style={{ marginBottom: '10px' }}
                                            value={caseDetails?.caseTitle}
                                            onChange={(e) => handleInputChange('caseTitle', e.target.value)}
                                        />
                                    ) : (
                                        <Text strong className='ms-1'>{caseDetails?.caseTitle}</Text>
                                    )}
                                </div>
                                <div>
                                    <Text>Number:</Text>
                                    {isEditing ? (
                                        <Input
                                            style={{ marginBottom: '10px' }}
                                            value={caseDetails?.caseNumber}
                                            onChange={(e) => handleInputChange('caseNumber', e.target.value)}
                                        />
                                    ) : (
                                        <Text strong className='ms-1'>{caseDetails?.caseNumber}</Text>
                                    )}
                                </div>

                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>Placing:</Text>
                                    {isEditing ? (
                                        <Input
                                            style={{ marginBottom: '10px' }}
                                            value={caseDetails?.casePlacing}
                                            onChange={(e) => handleInputChange('casePlacing', e.target.value)}
                                        />
                                    ) : (
                                        <Text strong className='ms-1'>{caseDetails?.casePlacing}</Text>
                                    )}
                                </div>
                                <div>
                                    <Text>Status:</Text>
                                    {isEditing ? (
                                        <Select
                                            style={{ width: '100%', marginBottom: '10px' }}
                                            value={caseDetails?.caseStatus}
                                            onChange={(value) => handleInputChange('caseStatus', value)}
                                        >
                                            <Select.Option value="Approved">Approved</Select.Option>
                                            <Select.Option value="Rejected">Rejected</Select.Option>
                                        </Select>
                                    ) : (
                                        <Text strong className='ms-1'>{caseDetails?.caseStatus}</Text>
                                    )}

                                </div>
                                {/* <div>
                                    <Text>Created Date:</Text>
                                    {isEditing ? (
                                        <DatePicker
                                            style={{ marginBottom: '10px', width: '100%' }}
                                            value={caseDetails?.createdDate ? moment(caseDetails.createdDate) : null}
                                            onChange={(date) => handleInputChange('createdDate', date ? date.toISOString() : null)}
                                            format="YYYY-MM-DD HH:mm:ss" // or any other format you prefer
                                            showTime // to allow time selection
                                        />
                                    ) : (
                                        <Text strong className='ms-1'>
                                            {new Date(caseDetails?.createdDate).toLocaleString()}
                                        </Text>
                                    )}
                                </div> */}
                                <div>
                                    <Text>Legal Status:</Text>
                                    {isEditing ? (
                                        <Input
                                            style={{ marginBottom: '10px' }}
                                            value={caseDetails?.legalStatus}
                                            onChange={(e) => handleInputChange('legalStatus', e.target.value)}
                                        />
                                    ) : (
                                        <Text strong className='ms-1'>{caseDetails?.legalStatus}</Text>
                                    )}
                                </div>
                            </Col>
                        </Row>

                        {/* <div className='mt-3'>
                            Files:
                            {caseDetails?.caseFiles && caseDetails?.caseFiles.length > 0 ? caseDetails?.caseFiles.map((file, key) => (
                                <div key={key + file.docId}>
                                    <p>{file.docName}</p>
                                    <p>{file.docUrl}</p>
                                </div>
                            )) : " No files attached..."}
                        </div> */}

                        <Divider />

                        {isEditing ? (
                            <div>
                                <Button type="primary" onClick={onSave}>Save</Button>
                                <Button style={{ marginLeft: '8px' }} onClick={onCancel}>Cancel</Button>
                            </div>
                        ) : (
                            <Button type="primary" onClick={onEdit}>Edit</Button>
                        )}
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card style={{
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        borderRadius: '8px',
                        padding: '10px',
                    }}>

                        <Title level={5}>User Information</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>CNIC:</Text>
                                    <Text strong className='ms-1'> {userDetails?.cnicNo}</Text>
                                </div>
                                <div>
                                    <Text>Contact Number:</Text>
                                    <Text strong className='ms-1'> {userDetails?.contactNumber}</Text>
                                </div>
                                <div>
                                    <Text>Current Address:</Text>
                                    <Text strong className='ms-1'> {userDetails?.currAddress}</Text>
                                </div>
                                <div>
                                    <Text>Email:</Text>
                                    <Text strong className='ms-1'> {userDetails?.email}</Text>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>

                                <div>
                                    <Text>Full Name:</Text>
                                    <Text strong className='ms-1'> {userDetails?.fullName}</Text>
                                </div>
                                <div>
                                    <Text>Permanent Address:</Text>
                                    <Text strong className='ms-1'> {userDetails?.permAddress}</Text>
                                </div>
                                <div>
                                    <Text>User ID:</Text>
                                    <Text strong className='ms-1'> {userDetails?.userId}</Text>
                                </div>
                            </Col>
                        </Row>

                    </Card>
                </Col>

            </Row>
        </>
    )
}
