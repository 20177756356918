import { ApiCall } from "./ApiCall";

export const BASE_URL = "https://adminapi.wakalatnaama.com";

const COMPLETE_PENDING_CASE_COUNT = "/api/Cases/CompletedPendingCasesCount";

const GET_LAWYER_LIST = "/api/Users/GetLawyerList";
const GET_LAWYER_DETAILS = "/api/Users/GetLawyerDetails";
const GET_LAWYER_HOME = "/api/Users/GetLayerHome";
const GET_CITIZEN_DETAILS = "/api/Users/GetCitizenDetails";
const GET_USERS = "/api/Users";
const CREATE_USER = "/api/Users";
const DELETE_USER = "/api/Users/DeleteUserByAdmin";
const UPDATE_USER = "/api/Users";
const FIND_USER = "/api/Users/Find";
const GET_ALL_USER = "/api/Users/GetAllUser";

const REGISTER_ACCOUNT = "/api/Account/Register";
const SIGN_IN_ACCOUNT = "/api/Account/SignIn";
const GET_ACCOUNT_DATA = "/api/Account/GetUserClaims";
const GET_ACCOUNT = "/api/Account";
const CREATE_ACCOUNT = "/api/Account";
const DELETE_ACCOUNT = "/api/Account";
const UPDATE_ACCOUNT = "/api/Account";
const FIND_ACCOUNT = "/api/Account/Find";
const UPDATE_DEVICE_ACCOUNT = "/api/Account/UpdateDeviceToken";

const GET_ROLES = "/api/Role";
const CREATE_ROLE = "/api/Role";
const DELETE_ROLE = "/api/Role";
const UPDATE_ROLE = "/api/Role";
const FIND_ROLE = "/api/Role/Find";

const GET_USER_REVIEW = "/api/Review/GetUserReview";
const GET_REVIEWS = "/api/Review";
const CREATE_REVIEW = "/api/Review";
const DELETE_REVIEW = "/api/Review";
const UPDATE_REVIEW = "/api/Review";
const FIND_REVIEW = "/api/Review/Find";

const GET_PARTY_STATUS = "/api/PartyStatus";
const CREATE_PARTY_STATUS = "/api/PartyStatus";
const DELETE_PARTY_STATUS = "/api/PartyStatus";
const UPDATE_PARTY_STATUS = "/api/PartyStatus";
const FIND_PARTY_STATUS = "/api/PartyStatus/Find";

const GET_PRIVATE_CHAT = "/api/Message/GetPrivateChat";
const MARK_READ = "/api/Message/MarkRead";
const GET_ALL_UNREAD_MESSAGE_COUNT = "/api/Message/GetAllUnReadMessageCount";
const GET_UNREAD_MESSAGE_COUNT_BY_USER =
  "/api/Message/GetUnReadMessageCountByUser";
const SEND_MESSAGE = "/api/Message/Send";
const DELETE_MESSAGE = "/api/Message/Delete";
const REPLY_MESSAGE = "/api/Message/Reply";
const GET_CHAT_HISTORY = "/api/Message/GetChatHistory?";

const GET_CHAT_MAIN_DATA = "/api/Message/GetChatBoxMainData";
const MARK_READ_MESSAGE = "/api/Message/MarkMessagesRead";

const UPLOAD_FILE = "/api/Document/UploadFile";
const GET_FILES = "/api/Document/GetFiles";
const DOWNLOAD_FILE = "/api/Document/DownloadFile";
const CREATE_FOLDER = "/api/Document/CreateFolder";
const DELETE_FILE = "/api/Document/DeleteFile";

const GET_CATEGORIES = "/api/Category";
const CREATE_CATEGORY = "/api/Category";
const DELETE_CATEGORY = "/api/Category";
const UPDATE_CATEGORY = "/api/Category";
const FIND_CATEGORY = "/api/Category/Find";

const CREATE_CASE = "/api/Cases/CreateCase";
const GET_USER_CASE = "/api/Cases/GetUserCase";
const GET_USER_DATE_LIST = "/api/Cases/GetUserDateList";
const GET_CASES = "/api/Cases";
const GET_ALL_CASES_BY_ADMIN = "/api/Cases/GetAllCasesByAdmin";

const CREATE_NEW_CASE = "/api/Cases";
const DELETE_CASE = "/api/Cases";
const UPDATE_CASE = "/api/Cases";
const FIND_CASE = "/api/Cases/Find";
const GET_CASE_DETAILS = "/api/Cases/GetAdminCaseDetails";
const UPDATE_CASE_STATUS_BY_ADMIN = "/api/Cases/UpdateCaseStatusByAdmin";
const GET_CASES_FOR_ADMIN_APPROVAL = "/api/Cases/GetCasesForAdminApproval";
const GET_LAWYER_DETAILS_BY_ADMIN = "/api/Users/GetLawyerProfileInfoForAdmin";
const ACCEPT_REJECR_LAWYER_BY_ADMIN = "/api/Users/AcceptRejectLawyerByAdmin";

// reports
const GET_CASES_REPORTS = "/api/Cases/GetCasesReport";

const GET_CASE_JURISDICTION = "/api/CaseJurisdiction";
const CREATE_CASE_JURISDICTION = "/api/CaseJurisdiction";
const DELETE_CASE_JURISDICTION = "/api/CaseJurisdiction";
const UPDATE_CASE_JURISDICTION = "/api/CaseJurisdiction";
const FIND_CASE_JURISDICTION = "/api/CaseJurisdiction/Find";

const GET_NOTIFICATIONS = "/api/Notification";
const GET_ALL_NOTIFICATIONS_BY_USER = "/api/Notification/AllNotificationByUser";
const GET_ALL_UNREAD_NOTIFICATIONS_BY_USER =
  "/api/Notification/AllUnReadNotificationByUser";
const POST_MARK_USER_ALL_NOTIFICATIONS_READ =
  "/api/Notification/MarkUserAllNotification";
const POST_MARK_SINGLE_NOTIFICATION_READ =
  "/api/Notification/MarkSingleNotificationRead";

const GET_REJECTED_REASON_LIST = "/api/Settings/GetRejectionList";

const GET_DOWNLOADABLE_DOCUMENTS =
  "/api/Settings/GetDownloadableDocuments?docType=";
const UPLOAD_USER_DOCUMENTS = "/api/Settings/UploadUserDocuments";
const DELETE_DOCUMENT = "/api/Settings/DeleteUserDocuments"; //docId=1&docType=1

const CREATE_BANNER_CONTENT = "/api/Settings/UploadBannerContent";
const GET_BANNER_CONTENT = "/api/Settings/GetBannerList?";
const DELETE_BANNER_CONTENT = "/api/Settings/DeleteBanner/";

const CREATE_CASE_DATE_BY_ADMIN = `/api/Cases/CreateCaseDateByAdmin`;
const CREATE_UPDATE_CASE_DATE_BY_ADMIN = `/api/Cases/CreateUpdateCaseByAdmin`;
const UPDATE_CASE_DATE_BY_ADMIN = `/api/Cases/UpdateCaseDateByAdmin`;
const GET_CASE_DATE_LIST_BY_ADMIN = `/api/Cases/GetDateListByCaseId?`;
const GET_CASE_DATE_BY_ADMIN = `/api/Cases/GetCaseDateByAdmin?`;
const DELETE_CASE_DATE = `/api/Cases/DeleteCaseDateByAdmin?`;
const DELETE_CASE_BY_ID = `/api/Cases/DeleteCaseByAdmin?`;
const GET_APPROVED_CASE_DETAILS = "/api/Cases/GetApprovedCaseDetailsByAdmin";
const GET_CASE_STATUS_BY_CASE_ID = "/api/Settings/GetCaseStatusesByCaseId?";

const GET_NOT_ATTENDED_CASES = "/api/Cases/GetNotAttendedCaseDates?";

export const getCountCompletePendingCases = (headers) => {
  let url = BASE_URL + COMPLETE_PENDING_CASE_COUNT;
  return ApiCall(url, "get", null, headers);
};

export const getLawyerList = (headers) => {
  let url = BASE_URL + GET_LAWYER_LIST;
  return ApiCall(url, "get", null, headers);
};

export const getLawyerDetails = (headers) => {
  let url = BASE_URL + GET_LAWYER_DETAILS;
  return ApiCall(url, "get", null, headers);
};

export const getLawyerHome = (headers) => {
  let url = BASE_URL + GET_LAWYER_HOME;
  return ApiCall(url, "get", null, headers);
};

export const getCitizenDetails = (headers) => {
  let url = BASE_URL + GET_CITIZEN_DETAILS;
  return ApiCall(url, "get", null, headers);
};

export const getUsers = (headers) => {
  let url = BASE_URL + GET_USERS;
  return ApiCall(url, "get", null, headers);
};

export const createUser = (data, headers) => {
  let url = BASE_URL + CREATE_USER;
  return ApiCall(url, "post", data, headers);
};

export const deleteUser = (query) => {
  let url = BASE_URL + DELETE_USER + query;
  return ApiCall(url, "post", null);
};

export const updateUser = (data, headers) => {
  let url = BASE_URL + UPDATE_USER;
  return ApiCall(url, "put", data, headers);
};

export const findUser = (headers) => {
  let url = BASE_URL + FIND_USER;
  return ApiCall(url, "get", null, headers);
};
export const getAllUser = (params, headers) => {
  let url = BASE_URL + GET_ALL_USER + params;
  return ApiCall(url, "get", null, headers);
};

export const getRoles = (headers) => {
  let url = BASE_URL + GET_ROLES;
  return ApiCall(url, "get", null, headers);
};

export const createRole = (data, headers) => {
  let url = BASE_URL + CREATE_ROLE;
  return ApiCall(url, "post", data, headers);
};

export const deleteRole = (headers) => {
  let url = BASE_URL + DELETE_ROLE;
  return ApiCall(url, "delete", null, headers);
};

export const updateRole = (data, headers) => {
  let url = BASE_URL + UPDATE_ROLE;
  return ApiCall(url, "put", data, headers);
};

export const findRole = (headers) => {
  let url = BASE_URL + FIND_ROLE;
  return ApiCall(url, "get", null, headers);
};

export const getUserReview = (headers) => {
  let url = BASE_URL + GET_USER_REVIEW + headers;
  return ApiCall(url, "get", null);
};

export const getReviews = (headers) => {
  let url = BASE_URL + GET_REVIEWS;
  return ApiCall(url, "get", null, headers);
};

export const createReview = (data, headers) => {
  let url = BASE_URL + CREATE_REVIEW;
  return ApiCall(url, "post", data, headers);
};

export const deleteReview = (headers) => {
  let url = BASE_URL + DELETE_REVIEW;
  return ApiCall(url, "delete", null, headers);
};

export const updateReview = (data, headers) => {
  let url = BASE_URL + UPDATE_REVIEW;
  return ApiCall(url, "put", data, headers);
};

export const findReview = (headers) => {
  let url = BASE_URL + FIND_REVIEW;
  return ApiCall(url, "get", null, headers);
};

export const getPartyStatus = (headers) => {
  let url = BASE_URL + GET_PARTY_STATUS;
  return ApiCall(url, "get", null, headers);
};

export const createPartyStatus = (data, headers) => {
  let url = BASE_URL + CREATE_PARTY_STATUS;
  return ApiCall(url, "post", data, headers);
};

export const deletePartyStatus = (headers) => {
  let url = BASE_URL + DELETE_PARTY_STATUS;
  return ApiCall(url, "delete", null, headers);
};

export const updatePartyStatus = (data, headers) => {
  let url = BASE_URL + UPDATE_PARTY_STATUS;
  return ApiCall(url, "put", data, headers);
};

export const findPartyStatus = (headers) => {
  let url = BASE_URL + FIND_PARTY_STATUS;
  return ApiCall(url, "get", null, headers);
};

export const getChatsForUser = (query) => {
  let url = BASE_URL + GET_CHAT_MAIN_DATA + query;
  return ApiCall(url, "get");
};

export const markMessageRead = (data) => {
  let url = BASE_URL + MARK_READ_MESSAGE + data;
  return ApiCall(url, "post");
};

export const getPrivateChat = (data, headers) => {
  let url = BASE_URL + GET_PRIVATE_CHAT;
  return ApiCall(url, "post", data, headers);
};

export const getChatHistory = (query, headers) => {
  let url = BASE_URL + GET_CHAT_HISTORY + query;
  return ApiCall(url, "get", headers);
};

export const markRead = (data, headers) => {
  let url = BASE_URL + MARK_READ;
  return ApiCall(url, "post", data, headers);
};

export const getAllUnreadMessageCount = (data, headers) => {
  let url = BASE_URL + GET_ALL_UNREAD_MESSAGE_COUNT;
  return ApiCall(url, "post", data, headers);
};

export const getUnreadMessageCountByUser = (data, headers) => {
  let url = BASE_URL + GET_UNREAD_MESSAGE_COUNT_BY_USER;
  return ApiCall(url, "post", data, headers);
};

export const sendMessage = (data, headers) => {
  let url = BASE_URL + SEND_MESSAGE;
  return ApiCall(url, "post", data, headers);
};

export const deleteMessage = (data, headers) => {
  let url = BASE_URL + DELETE_MESSAGE;
  return ApiCall(url, "post", data, headers);
};

export const replyMessage = (data, headers) => {
  let url = BASE_URL + REPLY_MESSAGE;
  return ApiCall(url, "post", data, headers);
};

export const uploadFile = (data, query) => {
  let url = BASE_URL + UPLOAD_FILE + query;
  return ApiCall(url, "post", data, "multipart/form-data");
};

export const getFiles = (headers) => {
  let url = BASE_URL + GET_FILES;
  return ApiCall(url, "get", null, headers);
};

export const downloadFile = (data, headers) => {
  let url = BASE_URL + DOWNLOAD_FILE;
  return ApiCall(url, "get", data, headers);
};

export const createFolder = (data, headers) => {
  let url = BASE_URL + CREATE_FOLDER;
  return ApiCall(url, "post", data, headers);
};

export const deleteFile = (data, headers) => {
  let url = BASE_URL + DELETE_FILE;
  return ApiCall(url, "post", data, headers);
};

export const getCategories = (headers) => {
  let url = BASE_URL + GET_CATEGORIES;
  return ApiCall(url, "get", null, headers);
};

export const createCategory = (data, headers) => {
  let url = BASE_URL + CREATE_CATEGORY;
  return ApiCall(url, "post", data, headers);
};

export const deleteCategory = (headers) => {
  let url = BASE_URL + DELETE_CATEGORY;
  return ApiCall(url, "delete", null, headers);
};

export const updateCategory = (data, headers) => {
  let url = BASE_URL + UPDATE_CATEGORY;
  return ApiCall(url, "put", data, headers);
};

export const findCategory = (headers) => {
  let url = BASE_URL + FIND_CATEGORY;
  return ApiCall(url, "get", null, headers);
};

export const createCase = (data, headers) => {
  let url = BASE_URL + CREATE_CASE;
  return ApiCall(url, "post", data, headers);
};

export const getUserCase = (headers) => {
  let url = BASE_URL + GET_USER_CASE;
  return ApiCall(url, "get", null, headers);
};

export const getUserDateList = (headers) => {
  let url = BASE_URL + GET_USER_DATE_LIST;
  return ApiCall(url, "get", null, headers);
};

export const getCases = (headers) => {
  let url = BASE_URL + GET_CASES;
  return ApiCall(url, "get", null, headers);
};

export const getAllCasesByAdmin = (headers) => {
  let url = BASE_URL + GET_ALL_CASES_BY_ADMIN;
  return ApiCall(url, "get", null, headers);
};

export const createNewCase = (data, headers) => {
  let url = BASE_URL + CREATE_NEW_CASE;
  return ApiCall(url, "post", data, headers);
};

export const deleteCase = (headers) => {
  let url = BASE_URL + DELETE_CASE;
  return ApiCall(url, "delete", null, headers);
};

export const updateCase = (data, headers) => {
  let url = BASE_URL + UPDATE_CASE;
  return ApiCall(url, "put", data, headers);
};

export const findCase = (headers) => {
  let url = BASE_URL + FIND_CASE;
  return ApiCall(url, "get", null, headers);
};

export const getCaseDetails = (query) => {
  let url = BASE_URL + GET_CASE_DETAILS + query;
  return ApiCall(url, "get", null);
};

export const getCasesForAdminApproval = (query) => {
  let url = BASE_URL + GET_CASES_FOR_ADMIN_APPROVAL;
  return ApiCall(url, "get", null);
};

export const getLawyerDetailsByAdmin = (query) => {
  let url = BASE_URL + GET_LAWYER_DETAILS_BY_ADMIN + query;
  return ApiCall(url, "get", null);
};

export const acceptRejectLawyerByAdmin = (data) => {
  let url = BASE_URL + ACCEPT_REJECR_LAWYER_BY_ADMIN;
  return ApiCall(url, "post", data);
};

export const updateCaseStatusByAdmin = (data) => {
  let url = BASE_URL + UPDATE_CASE_STATUS_BY_ADMIN;
  return ApiCall(url, "post", data);
};

export const getCasesReports = (data) => {
  let url = BASE_URL + GET_CASES_REPORTS;
  return ApiCall(url, "post", data);
};

export const getCaseJurisdiction = (headers) => {
  let url = BASE_URL + GET_CASE_JURISDICTION;
  return ApiCall(url, "get", null, headers);
};

export const createCaseJurisdiction = (data, headers) => {
  let url = BASE_URL + CREATE_CASE_JURISDICTION;
  return ApiCall(url, "post", data, headers);
};

export const deleteCaseJurisdiction = (headers) => {
  let url = BASE_URL + DELETE_CASE_JURISDICTION;
  return ApiCall(url, "delete", null, headers);
};

export const updateCaseJurisdiction = (data, headers) => {
  let url = BASE_URL + UPDATE_CASE_JURISDICTION;
  return ApiCall(url, "put", data, headers);
};

export const findCaseJurisdiction = (headers) => {
  let url = BASE_URL + FIND_CASE_JURISDICTION;
  return ApiCall(url, "get", null, headers);
};

export const registerAccount = (data, headers) => {
  let url = BASE_URL + REGISTER_ACCOUNT;
  console.log("data", data);
  return ApiCall(url, "post", data, headers);
};

export const signInAccount = (data, headers) => {
  let url = BASE_URL + SIGN_IN_ACCOUNT;
  return ApiCall(url, "post", data, headers);
};

export const getAccountData = (data, headers) => {
  let url = BASE_URL + GET_ACCOUNT_DATA;
  return ApiCall(url, "post", data, headers);
};

export const getAccount = (data, headers) => {
  let url = BASE_URL + GET_ACCOUNT;
  return ApiCall(url, "get", data, headers);
};

export const createAccount = (data, headers) => {
  let url = BASE_URL + CREATE_ACCOUNT;
  return ApiCall(url, "post", data, headers);
};

export const deleteAccount = (data) => {
  let url = BASE_URL + DELETE_ACCOUNT;
  return ApiCall(url, "delete", data);
};

export const updateAccount = (data, headers) => {
  let url = BASE_URL + UPDATE_ACCOUNT;
  return ApiCall(url, "put", data, headers);
};

export const findAccount = (headers) => {
  let url = BASE_URL + FIND_ACCOUNT;
  return ApiCall(url, "get", null, headers);
};

export const updateDeviceAccount = (data) => {
  let url = BASE_URL + UPDATE_DEVICE_ACCOUNT;
  return ApiCall(url, "post", data);
};

export const getRejectedReasonList = () => {
  let url = BASE_URL + GET_REJECTED_REASON_LIST;
  return ApiCall(url, "get", null);
};

export const getDownloadableDocument = (docType) => {
  let url = BASE_URL + GET_DOWNLOADABLE_DOCUMENTS + docType;
  return ApiCall(url, "get");
};

export const uploadUserDocument = (data) => {
  let url = BASE_URL + UPLOAD_USER_DOCUMENTS;
  return ApiCall(url, "post", data);
};

export const deleteDocument = (query) => {
  let url = BASE_URL + DELETE_DOCUMENT + query; //
  return ApiCall(url, "delete");
};

export const getBannerContent = (data, headers) => {
  let url = BASE_URL + GET_BANNER_CONTENT + data;
  return ApiCall(url, "get", data, headers);
};

export const createBannerContent = (data, headers) => {
  let url = BASE_URL + CREATE_BANNER_CONTENT;
  return ApiCall(url, "post", data, headers);
};

export const deleteBannerContent = (bannerId) => {
  let url = BASE_URL + DELETE_BANNER_CONTENT + bannerId;
  return ApiCall(url, "delete");
};

export const createUpdateCaseDetailsByAdmin = (data, headers) => {
  let url = BASE_URL + CREATE_UPDATE_CASE_DATE_BY_ADMIN;
  return ApiCall(url, "post", data, headers);
};

export const createCaseDateByAdmin = (data, headers) => {
  let url = BASE_URL + CREATE_CASE_DATE_BY_ADMIN;
  return ApiCall(url, "post", data, headers);
};

// Update Case Date by Admin
export const updateCaseDateByAdmin = (data, headers) => {
  let url = BASE_URL + UPDATE_CASE_DATE_BY_ADMIN;
  return ApiCall(url, "post", data, headers);
};

// Get Case Date by Admin
export const getCaseDateListByAdmin = (query, headers) => {
  let url = BASE_URL + GET_CASE_DATE_LIST_BY_ADMIN + query;
  return ApiCall(url, "get", null, headers);
};

export const getCaseDateByAdmin = (query, headers) => {
  let url = BASE_URL + GET_CASE_DATE_BY_ADMIN + query;
  return ApiCall(url, "get", null, headers);
};

export const deleteCaseById = (query) => {
  let url = BASE_URL + DELETE_CASE_BY_ID + query;
  return ApiCall(url, "post", null);
};

export const deleteCaseDateById = (query) => {
  let url = BASE_URL + DELETE_CASE_DATE + query;
  return ApiCall(url, "post", null);
};
export const getApprovedCaseDetails = (query) => {
  let url = BASE_URL + GET_APPROVED_CASE_DETAILS + query;
  return ApiCall(url, "get", null);
};

export const getCaseStatusesByCaseId = (query) => {
  let url = BASE_URL + GET_CASE_STATUS_BY_CASE_ID + query;
  return ApiCall(url, "get", null);
};

export const getAllNotifications = (headers) => {
  let url = BASE_URL + GET_ALL_NOTIFICATIONS_BY_USER;
  return ApiCall(url, "get", null, headers);
};
export const getUnreadNotifications = (headers) => {
  let url = BASE_URL + GET_ALL_UNREAD_NOTIFICATIONS_BY_USER;
  return ApiCall(url, "get", null, headers);
};
export const markAllNotificationsRead = (headers) => {
  let url = BASE_URL + POST_MARK_USER_ALL_NOTIFICATIONS_READ;
  return ApiCall(url, "post", null, headers);
};
export const readNotifications = (notificationId) => {
  let url =
    BASE_URL +
    POST_MARK_SINGLE_NOTIFICATION_READ +
    "?NotificationId=" +
    notificationId;
  return ApiCall(url, "post", null);
};

export const getNotAttendedCases = (query) => {
  let url = BASE_URL + GET_NOT_ATTENDED_CASES + query;
  return ApiCall(url, "get", null);
};
