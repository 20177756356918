import React, { useEffect, useRef } from 'react';
import "./Messages.css";
import { useSelector } from 'react-redux';
import moment from 'moment';
import { List } from 'antd';
import download from 'downloadjs';
import { PaperClipOutlined } from '@ant-design/icons';

export const MessagesList = ({ privateMessage }) => {
    const userDataRedux = useSelector(state => state.UserData);
    const scrollableContainerRef = useRef(null);

    useEffect(() => {
        // Scroll to the bottom whenever privateMessage updates
        if (scrollableContainerRef.current) {
            scrollableContainerRef.current.scrollTop = scrollableContainerRef.current.scrollHeight;
        }
    }, [privateMessage]);



    const handleFileDownload = (fileUrl) => {
        // Extract the filename from the URL (last part of the URL)
        const fileName = fileUrl.split('/').pop();

        // Fetch the file and trigger download
        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => download(blob, fileName))
            .catch(err => console.error('Error downloading the file:', err));
    };

    const renderMessage = (message) => {
        const isAttachment = /\.(pdf|docx?|png|jpg)$/.test(message.content);
        if (isAttachment) {
            const fileName = message.content.split('/').pop();
            return (
                <p key={message.id}>
                    <a onClick={() => handleFileDownload(message.content)} style={{ cursor: 'pointer', color: '#1890ff' }}>
                        <PaperClipOutlined style={{ marginRight: '8px', color: '#1890ff' }} />      {fileName}
                    </a>
                </p>
            );
        } else {
            return <p key={message.id}>{message.content}</p>;
        }
    };



    return (
        <div className='scrollable-container' ref={scrollableContainerRef}>
            <List
                itemLayout="horizontal"
                dataSource={privateMessage}
                renderItem={item => (
                    <List.Item
                        style={{
                            display: 'flex',
                            justifyContent: item.fromUserId === userDataRedux?.userId ? 'flex-end' : 'flex-start',
                        }}
                    >
                        <div
                            style={{
                                width: '50%',
                                textAlign: item.fromUserId === userDataRedux?.userId ? 'right' : 'left',
                                background: item.fromUserId === userDataRedux?.userId ? '#d1e7dd' : '#f8d7da',
                                borderRadius: '10px',
                                padding: '10px',
                            }}
                        >
                            <List.Item.Meta
                                style={{ textAlign: 'left' }}
                                title={<p className='mb-0'>
                                    {renderMessage(item)}
                                </p>}
                                description={
                                    <>
                                        <h6>{item.fullName}</h6>
                                        <p className='m-0' style={{ fontSize: '12px' }}>
                                            {moment(item.createdDate).local().format('lll')}
                                        </p>
                                    </>
                                }
                            />
                        </div>
                    </List.Item>
                )}
            />
        </div>
    );
};
