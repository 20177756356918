import React, { useState } from 'react';
import { Steps, Button, Form, Select, Modal, Row, Col } from 'antd';
import { Card, Typography, Divider } from 'antd';

const { Text, Title } = Typography;

const { Step } = Steps;
const { Option } = Select;


export const StepReview = (
    {
        caseDetails,
        userDetails,
        primaryLawyerDetails,
        redundentLawyerDetails,
        employeeDetails
    }
) => {

    return (
        <div>
            <Row gutter={[16, 16]} justify="space-between">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card style={{
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        borderRadius: '8px',
                        padding: '10px',
                    }}>
                        <Title level={5}>Case Details</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>ID:</Text>
                                    <Text strong> {caseDetails?.caseId}</Text>
                                </div>
                                <div>
                                    <Text>Title:</Text>
                                    <Text strong> {caseDetails?.caseTitle}</Text>
                                </div>
                                <div>
                                    <Text>Number:</Text>
                                    <Text strong> {caseDetails?.caseNumber}</Text>
                                </div>

                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>Status:</Text>
                                    <Text strong> {caseDetails?.caseStatus}</Text>
                                </div>
                                <div>
                                    <Text>Placing:</Text>
                                    <Text strong> {caseDetails?.casePlacing}</Text>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>

                                <div>
                                    <Text>Created Date:</Text>
                                    <Text strong> {new Date(caseDetails?.createdDate).toLocaleString()}</Text>
                                </div>
                                <div>
                                    <Text>Legal Status:</Text>
                                    <Text strong> {caseDetails?.legalStatus}</Text>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card style={{
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        borderRadius: '8px',
                        padding: '10px',
                    }}>

                        <Title level={5}>User Information</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>CNIC:</Text>
                                    <Text strong> {userDetails?.cnicNo}</Text>
                                </div>
                                <div>
                                    <Text>Contact Number:</Text>
                                    <Text strong> {userDetails?.contactNumber}</Text>
                                </div>
                                <div>
                                    <Text>Current Address:</Text>
                                    <Text strong> {userDetails?.currAddress}</Text>
                                </div>

                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>Email:</Text>
                                    <Text strong> {userDetails?.email}</Text>
                                </div>
                                <div>
                                    <Text>Full Name:</Text>
                                    <Text strong> {userDetails?.fullName}</Text>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>


                                <div>
                                    <Text>Permanent Address:</Text>
                                    <Text strong> {userDetails?.permAddress}</Text>
                                </div>
                                <div>
                                    <Text>User ID:</Text>
                                    <Text strong> {userDetails?.userId}</Text>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                {primaryLawyerDetails && (
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Card
                            style={{
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                borderRadius: '8px',
                                padding: '20px',
                            }}
                        >
                            <Title level={5}>Primary Lawyer Details</Title>
                            <Text>Name: {primaryLawyerDetails.fullName}</Text>
                            <br />
                            <Text>Email: {primaryLawyerDetails.email}</Text>
                            <br />
                            <Text>Phone: {primaryLawyerDetails.contactNumber}</Text>
                        </Card>
                    </Col>
                )}
                {redundentLawyerDetails && (
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Card
                            style={{
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                borderRadius: '8px',
                                padding: '20px',
                            }}
                        >
                            <Title level={5}>Redundent Lawyer Details</Title>
                            <Text>Name: {redundentLawyerDetails.fullName}</Text>
                            <br />
                            <Text>Email: {redundentLawyerDetails.email}</Text>
                            <br />
                            <Text>Phone: {redundentLawyerDetails.contactNumber}</Text>
                        </Card>
                    </Col>
                )}
                {employeeDetails && (
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Card
                            style={{
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                borderRadius: '8px',
                                padding: '20px',
                            }}
                        >
                            <Title level={5}>Employee Details</Title>
                            <Text>Name: {employeeDetails.fullName}</Text>
                            <br />
                            <Text>Email: {employeeDetails.email}</Text>
                            <br />
                            <Text>Phone: {employeeDetails.contactNumber}</Text>
                        </Card>
                    </Col>
                )}
            </Row>
        </div >
    )
}
