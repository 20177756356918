import React, { useState } from 'react';
import { Steps, Button, Form, Select, Modal, Row, Col } from 'antd';
import { Card, Typography, Divider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';


const { Text, Title } = Typography;

const { Step } = Steps;
const { Option } = Select;


export const StepSelectLawyer = ({ lawyerList, primaryLawyerDetails, setPrimaryLawyerDetails, redundentLawyerDetails, setRedundentLawyerDetails }) => {

    const onPrimaryLawyerChange = lawyerId => {
        if (lawyerId) {
            const lawyer = lawyerList.find(lawer => lawer.userId === lawyerId)
            if (lawyer) {
                setPrimaryLawyerDetails(lawyer);
            }
        } else {
            setPrimaryLawyerDetails(null);
        }
    };

    const onRedundentLawyerChange = lawyerId => {
        if (lawyerId) {
            const lawyer = lawyerList.find(lawer => lawer.userId === lawyerId)
            if (lawyer) {
                setRedundentLawyerDetails(lawyer);
            }
        } else {
            setRedundentLawyerDetails(null);
        }
    };

    return (
        <>
            {

                <Card style={{
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    borderRadius: '8px',
                    padding: '10px',
                    marginBottom: '20px',
                }}>
                    <Form layout="vertical">
                        <Form.Item label={<Title level={5}>Select Primary Lawyer</Title>}>
                            <Select
                                onChange={value => { onPrimaryLawyerChange(value) }}
                                style={{ width: '60%' }}
                                defaultValue={null}

                            >
                                <Option value={null}>Please Select</Option>
                                {
                                    lawyerList?.map((lawyer, key) => {
                                        return (
                                            <Option key={key} value={lawyer.userId}>{lawyer.fullName}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                    {primaryLawyerDetails && <div>
                        <div className='d-flex' style={{ alignItems: 'center', justifyContent: 'space-between', width: '60%' }}>
                            <Title level={5}>Primary Lawyer Details</Title>
                            <CloseOutlined
                                onClick={() => setPrimaryLawyerDetails(null)}
                                style={{ color: 'red' }} />
                        </div>
                        <Text>Name: {primaryLawyerDetails?.fullName}</Text>
                        <br />
                        <Text>Email: {primaryLawyerDetails?.email}</Text>
                        <br />
                        <Text>Phone: {primaryLawyerDetails?.contactNumber}</Text>
                    </div>}
                </Card>
            }
            {

                <Card style={{
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    borderRadius: '8px',
                    padding: '10px',
                    marginBottom: '20px',
                }}>
                    <Form layout="vertical">
                        <Form.Item label={<Title level={5}>Select Redundent Lawyer</Title>}>
                            <Select
                                onChange={value => { onRedundentLawyerChange(value) }}
                                style={{ width: '60%' }}
                                defaultValue={null}
                            >
                                <Option value={null}>Please Select</Option>
                                {
                                    lawyerList?.map((lawyer, key) => {
                                        return (
                                            <Option key={key} value={lawyer.userId}>{lawyer.fullName}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                    {redundentLawyerDetails && <div>
                        <div className='d-flex' style={{ alignItems: 'center', justifyContent: 'space-between', width: '60%' }}>
                            <Title level={5}>Redundent Lawyer Details</Title>
                            <CloseOutlined onClick={() => setRedundentLawyerDetails(null)} style={{ color: 'red' }} />
                        </div>
                        <Text>Name: {redundentLawyerDetails?.fullName}</Text>
                        <br />
                        <Text>Email: {redundentLawyerDetails?.email}</Text>
                        <br />
                        <Text>Phone: {redundentLawyerDetails?.contactNumber}</Text>
                    </div>}
                </Card>
            }



        </>
    )
}
