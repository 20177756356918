import React from 'react';
import { Modal, Button } from 'antd';

export const GenericModal = (props) => {
    const { visible, title, content, onCancel, footer,width=648 } = props
    return (
        <Modal
            visible={visible}
            title={title}
            onCancel={onCancel}
            width={width}
            footer={footer ? [
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary">
                    Submit
                </Button>,
            ] :
                ""}
        >
            {React.cloneElement(content, { ...props })}

        </Modal>
    );
};
