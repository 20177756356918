import React, { Fragment, useState } from 'react';
import { Layout, Modal } from 'antd';
import SiteHeader from './SiteHeader';
import Sidebar from './Sidebar';
import { useIdleTimer } from 'react-idle-timer';

const { Content } = Layout;
export const MainLayout = ({ children }) => {
    const [isMobileSidebar, setIsMobileSidebar] = useState(false)
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const toggleSidebar = () => {
        setIsMobileSidebar(!isMobileSidebar)
    }

    // Handle what happens when the user is idle
    const handleOnIdle = () => {
        Modal.warning({
            title: 'Your session is expired',
            content: 'You were inactive for 9 minutes. Please login again',
            onOk: () => {
                localStorage.clear()
                window.location.href = "/"
            },
        });
    };

    // Initialize the idle timer
    useIdleTimer({
        timeout: 9 * 60 * 1000,
        onIdle: handleOnIdle,
        debounce: 500,
        events: ['mousemove', 'keydown', 'scroll', 'click'],
    });

    return (
        <Fragment>
            <Layout className="section-bg-white">
                <SiteHeader
                    toggleSidebar={toggleSidebar}
                    toggleCollapsed={toggleCollapsed}
                    collapsed={collapsed}
                />
                <Layout className="section-bg-white fixed-layout-wrapper ">
                    <Sidebar
                        collapsed={collapsed}
                    >
                        <Content className="fixed-content-ml">
                            <main className="p-5">{children}</main>
                        </Content>
                    </Sidebar>
                    {/* <MobSidebar isMobileSidebar={isMobileSidebar} toggleSidebar={toggleSidebar} /> */}
                </Layout>
            </Layout>
        </Fragment>
    )
}
