import React, { useEffect, useState } from 'react';
import MyTable from '../../../components/MyTable';
import { getNotAttendedCases } from '../../../Utils/ApiManager';
import { Button, Form, DatePicker } from 'antd';
import { Spinner } from '../../../components/Spinner';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Item } = Form;

export const NotAttendedCases = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [, setIsAddModalVisible] = useState(false);
    const [isLoadingDelete,] = useState(false);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");

    const [form] = Form.useForm();

    useEffect(() => {

        if (start && end) {
            getData();
        }
    }, [start, end]); // Re-fetch data when the date range changes

    const getData = async () => {
        setIsLoading(true);
        try {
            // Format start and end dates properly
            // Call the API with the formatted start and end date
            const response = await getNotAttendedCases(`start=${start}&end=${end}`);

            // Log the API response to ensure correct data is fetched
            console.log('API Response:', response);

            // Set the response data to the state
            setData(response?.data?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDateChange = (dates) => {
        if (dates?.length > 1) {

            setStart(dates[0])
            setEnd(dates[1])
        }
    };

    const showAddModal = () => {
        setIsAddModalVisible(true);
        form.resetFields();
    };

    const tableColumns = [
        {
            title: 'Case ID',
            dataIndex: 'caseId',
            key: 'caseId',
        },
        {
            title: 'Case Title',
            dataIndex: 'caseTitle',
            key: 'caseTitle',
        },
        {
            title: 'Citizen Name',
            dataIndex: 'citizenName',
            key: 'citizenName',
        },
        {
            title: 'Lawyer Name',
            dataIndex: 'lawyerName',
            key: 'lawyerName',
        },
        {
            title: 'Hearing Date',
            dataIndex: 'hearingDate',
            key: 'hearingDate',
            render: (hearingDate) => (
                <span>{moment(hearingDate).local().format('LLL')}</span>
            ),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (createdDate) => (
                <span>{moment(createdDate).local().format('LLL')}</span>
            ),
        },
        {
            title: 'Case Status ID',
            dataIndex: 'caseStatusId',
            key: 'caseStatusId',
        },
    ];

    return (
        <div>
            {isLoadingDelete && <Spinner />}
            <div style={{}}>
                <h4 className='mb-4'>Not Attended Cases</h4>
                <div className='mb-4'>
                    <p className='mb-0'>Select start and end date</p>
                    <RangePicker onChange={handleDateChange} />
                </div>
                {/* <Button type="primary" onClick={showAddModal} style={{ marginLeft: '16px' }}>
                        Add
                    </Button> */}
            </div>
            <MyTable columns={tableColumns} data={data} isLoading={isLoading} />
        </div>
    );
};
