import React, { useState } from 'react';
import { Card, Form, Select, Collapse, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

export const StepEmployee = ({ employeeList, employeeDetails, setEmployeeDetails, }) => {

    const onEmployeeChange = employeeId => {
        const employee = employeeList.find(employee => employee.userId === employeeId)
        if (employee) {
            setEmployeeDetails(employee);
        }
    };


    return (
        <div>
            <Card
                style={{
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    borderRadius: '8px',
                    padding: '20px',
                    marginBottom: '20px',
                }}
            >
                <Form layout="vertical">
                    <Form.Item label={<Title level={5}>Select Employee</Title>}>
                        <Select
                            onChange={value => { onEmployeeChange(value) }}
                            style={{ width: '60%' }}
                            defaultValue={null}
                        >
                            <Option value={null}>Please Select</Option>
                            {
                                employeeList?.map((employee, key) => {
                                    return (
                                        <Option key={key} value={employee.userId}>{employee?.fullName}</Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                </Form>

                {
                    employeeDetails && (
                        < >
                            <div className='d-flex' style={{ alignItems: 'center', justifyContent: 'space-between', width: '60%' }}>
                                <Title level={5}>Employee Details</Title>
                                <CloseOutlined onClick={() => setEmployeeDetails(null)} style={{ color: 'red' }} />
                            </div>
                            <Text>Name: {employeeDetails.fullName}</Text>
                            <br />
                            <Text>Email: {employeeDetails.email}</Text>
                            <br />
                            <Text>Phone: {employeeDetails.contactNumber}</Text>
                        </>
                    )
                }
            </Card>

        </div >
    );
};

