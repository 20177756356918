import React, { useState, useEffect } from 'react';
import { Steps, Button, Select, Row, Col, Tooltip, Form, Input, Modal, DatePicker } from 'antd';
import { Card, Typography } from 'antd';
import moment from 'moment';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import MyTable from '../../../components/MyTable';
import { createCaseDateByAdmin, deleteCaseById, deleteCaseDateById, getCaseDateByAdmin, getCaseDateListByAdmin, getCaseDetails, getCaseStatusesByCaseId, updateCaseDateByAdmin } from '../../../Utils/ApiManager';
import useSelection from 'antd/es/table/hooks/useSelection';
import { useDispatch, useSelector } from 'react-redux';
import { GenericModal } from '../../../components/GenericModal';
import { resetCaseWithDateDetails } from '../../../redux/actions';
import { useNavigate } from 'react-router-dom';
import { PaperClipOutlined } from '@ant-design/icons';
import download from 'downloadjs';

const { Text, Title } = Typography;
const { Item } = Form;
const { Option } = Select;

export const CaseDateDetails = () => {
    const [caseDateDetails, setCaseDateDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const caseWithDateDetails = useSelector((state) => state.CaseWithDateDetails);

    useEffect(() => {
        if (caseWithDateDetails?.caseId) {
            getData();
        }
    }, [caseWithDateDetails]);

    const getData = async () => {
        setIsLoading(true);
        try {
            const response = await getCaseDateByAdmin(`caseId=${caseWithDateDetails?.caseId}&DateId=${1}`);
            console.log("response", response?.data?.data);
            setCaseDateDetails(response?.data?.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };
    const handleFileDownload = (fileUrl) => {
        // Extract the filename from the URL (last part of the URL)
        const fileName = fileUrl.split('/').pop();

        // Fetch the file and trigger download
        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => download(blob, fileName))
            .catch(err => console.error('Error downloading the file:', err));
    };

    return (
        <div>
            <div className="d-flex mb-2" style={{ justifyContent: 'space-between', alignContent: 'center' }}>
                <Title level={4}>Date Details</Title>
                <Button
                    type="primary"
                    danger
                    icon={<DeleteOutlined />}
                    style={{ marginLeft: '8px' }}
                >Delete Date </Button>
            </div>
            <Card style={{
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                borderRadius: '8px',
                padding: '10px',
            }}>
                <Row gutter={[16, 16]} justify="space-between">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Title level={5}> Details</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>ID:</Text>
                                    <Text strong> {caseDateDetails?.caseId}</Text>
                                </div>
                                <div>
                                    <Text>Title:</Text>
                                    <Text strong> {caseDateDetails?.dateTitle}</Text>
                                </div>
                                <div>
                                    <Text>Description:</Text>
                                    <Text strong> {caseDateDetails?.dateDescription}</Text>
                                </div>
                                <div>
                                    <Text>Status ID:</Text>
                                    <Text strong> {caseDateDetails?.caseStatusId}</Text>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>Hearing Date:</Text>
                                    <Text strong> {moment(caseDateDetails?.hearingDate).local().format('YYYY-MM-DD HH:mm')}</Text>
                                </div>
                                <div>
                                    <Text>Document ID:</Text>
                                    <Text strong> {caseDateDetails?.dateFiles[0]?.docId}</Text>
                                </div>
                                <div>
                                    <Text>Document Type:</Text>
                                    <Text strong> {caseDateDetails?.dateFiles[0]?.docType}</Text>
                                </div>
                                <div>
                                    <Text>Document Size:</Text>
                                    <Text strong> {caseDateDetails?.dateFiles[0]?.docSize}</Text>
                                </div>
                            </Col>
                        </Row>
                        <div className='mt-3'>
                            Files:
                            {caseDateDetails?.dateFiles && caseDateDetails.dateFiles.length > 0 ? caseDateDetails.dateFiles.map((file, key) => {
                                return (
                                    <div key={key + file.docId}>
                                        <p className='mb-0'>
                                            <Button
                                                type="link"
                                                icon={<PaperClipOutlined />}
                                                href={file.docUrl}
                                                onClick={() => handleFileDownload(file.docUrl)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {file.docName}
                                            </Button>
                                        </p>
                                    </div>
                                );
                            }) :
                                " No files attached..."
                            }
                        </div>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}
