import React, { useState } from 'react';
import { BellOutlined } from '@ant-design/icons';
import { Dropdown, Avatar, List, Row, Col, Badge } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import profile from '../media/profile.png'

const NotificationDropdown = ({ notifications, notificationCount }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate()

    const handleToggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleNotificationClick = (notificationId) => {
        // Handle click on a specific notification, e.g., navigate to the notification details page
        // You can replace this with your own logic
        navigate(`/notifications`);
        handleToggleDropdown()
    };

    const recentNotifications = notifications ?? []


    const dropdownContent = (
        <div className='mt-3'
            style={{
                minWidth: '300px',
                width: '350px',
                background: '#eee',
                padding: '10px'
            }}>
            <div className='d-flex justify-content-center'>
                <h6>Notifiactions</h6>
            </div>
            <p className='ml-3'>Today</p>
            {
                recentNotifications.slice(0, 3).map((item) => (
                    <Row
                        key={item.id}
                        className=""
                        style={{
                            margin: '10px 20px',
                            padding: '10px 20px',
                            background: item.isRead ? "white" : '#fff4e1',
                            borderRadius: '5px',
                            marginBottom: '10px',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleNotificationClick(item.id)}
                    >
                        <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Avatar src={item?.imageUrl ?? profile} /></Col>
                        <Col xs={12} sm={20} md={20} lg={20} xl={20}>
                            <span className=''>{item.content}</span>
                            <br />
                            <span className='' style={{ fontSize: '11px' }}>{moment(item.createdDate).local().format("lll")}</span>
                        </Col>

                    </Row>
                ))
            }
            <div className='d-flex justify-content-center'>
                <p className='mt-2'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        navigate(`/notifications`);
                        setShowDropdown(false)
                    }}>View all</p>
            </div>
        </div >
    );
    return (
        <Dropdown overlay={dropdownContent} placement="bottomRight" trigger={['click']} visible={showDropdown}>
            <div className="notification-dropdown">
                <Badge count={notificationCount} overflowCount={99}>
                    <BellOutlined
                        onClick={handleToggleDropdown}
                        className="mr-3 notify-icon"
                        style={{ fontSize: '20px' }}
                    />
                </Badge>
            </div>
        </Dropdown>
    );
};

export default NotificationDropdown;
