import React, { useEffect, useState } from 'react'
import {
    UserOutlined,
    UserAddOutlined,
    SolutionOutlined,
    SyncOutlined,
    FileAddOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { Card, Row, Col, Statistic } from 'antd';
import { getCountCompletePendingCases } from '../../Utils/ApiManager';
import { Spinner } from '../../components/Spinner';

export const DashboardCounts = () => {
    const userCount = 1000;
    const pendingCitizensCount = 200;
    const pendingLawyersCount = 150;
    const pendingRequestsCount = 50;
    const newCasesCount = 30;
    const pendingCasesCount = 20;

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        setIsLoading(true)
        try {
            const response = await getCountCompletePendingCases()
            console.log("response", response?.data?.data)
            setData(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }

    return (
        <div className='mb-5'>
            {isLoading && <Spinner />}

            <Row gutter={16}>
                <Col span={4}>
                    <Card
                        style={{
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            borderRadius: '8px',
                            padding: '10px',
                        }}
                        onClick={() => { }}
                    >
                        <Statistic
                            title="Total Users"
                            value={userCount}
                            prefix={<UserOutlined style={{ color: '#1890ff' }} />}
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        style={{
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            borderRadius: '8px',
                            padding: '10px',
                        }}
                        onClick={() => { }}
                    >
                        <Statistic
                            title="Pending Citizens"
                            value={pendingCitizensCount}
                            prefix={<UserAddOutlined style={{ color: '#52c41a' }} />}
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        style={{
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            borderRadius: '8px',
                            padding: '10px',
                        }}
                        onClick={() => { }}
                    >
                        <Statistic
                            title="Pending Lawyers"
                            value={pendingLawyersCount}
                            prefix={<SolutionOutlined style={{ color: '#faad14' }} />}
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        style={{
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            borderRadius: '8px',
                            padding: '10px',
                        }}
                        onClick={() => { }}
                    >
                        <Statistic
                            title="Pending Requests"
                            value={pendingRequestsCount}
                            prefix={<SyncOutlined style={{ color: '#13c2c2' }} />}
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        style={{
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            borderRadius: '8px',
                            padding: '10px',
                        }}
                        onClick={() => { }}
                    >
                        <Statistic
                            title="New Cases"
                            value={newCasesCount}
                            prefix={<FileAddOutlined style={{ color: '#eb2f96' }} />}
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        style={{
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            borderRadius: '8px',
                            padding: '10px',
                        }}
                        onClick={() => { }}
                    >
                        <Statistic
                            title="Pending Cases"
                            value={data[0]?.count || 0}
                            prefix={<ExclamationCircleOutlined style={{ color: '#f5222d' }} />}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
