import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
    { name: 'Total Users', count: 1000 },
    { name: 'Pending Citizens', count: 200 },
    { name: 'Pending Lawyers', count: 150 },
    { name: 'Pending Requests', count: 50 },
    { name: 'New Cases', count: 30 },
    { name: 'Pending Cases', count: 20 }
];

export const BarChartComponent = () => {
    return (
        <div>
            <BarChart
                width={600}
                height={300}
                data={data}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
        </div>
    );
}
