import React from 'react';
import {
    AppstoreOutlined,
    ContainerOutlined,
    MailOutlined,
    PieChartOutlined,
    DesktopOutlined,
    UserOutlined,
    TeamOutlined,
    UsergroupAddOutlined,
    FileOutlined,
    AppstoreAddOutlined,
    FileTextOutlined,
    UnorderedListOutlined,
    ContainerTwoTone,
} from '@ant-design/icons';
import { Col, Menu, Row } from 'antd';
import { useNavigate } from 'react-router-dom';


function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}
const items = [
    getItem('', '0'),
    getItem('', '00'),
    getItem('Dashboard', 'dashboard', <PieChartOutlined style={{ fontSize: '20px' }} />),
    getItem('User Management', 'usersManagement', <ContainerOutlined />, [
        getItem('Users', 'users', <UserOutlined style={{ fontSize: '20px' }} />),
        getItem('Lawyers', 'lawyers', <TeamOutlined style={{ fontSize: '20px' }} />),
        getItem('Citizens', 'citizens', <TeamOutlined style={{ fontSize: '20px' }} />),
        getItem('Roles', 'roles', <ContainerOutlined style={{ fontSize: '20px' }} />),
    ], 'subMenu'),
    getItem('Reports', 'reports', <ContainerOutlined />, [
        getItem('Zone cases', 'zoneCasesReportColumns', <ContainerOutlined style={{ fontSize: '20px' }} />),
        getItem('Lawyer cases', 'lawyerCasesReportColumns', <ContainerOutlined style={{ fontSize: '20px' }} />),
        getItem('Daily Cases', 'dailyCasesListToCourt', <ContainerOutlined style={{ fontSize: '20px' }} />),
        getItem('All users reports', 'allUsersReport', <ContainerOutlined style={{ fontSize: '20px' }} />),
    ], 'subMenu'),

    // getItem('Reviews', 'reviews', <FileTextOutlined style={{ fontSize: '20px' }} />),
    getItem('New Case', 'newcases', <FileTextOutlined style={{ fontSize: '20px' }} />),
    getItem('Messages', 'messages', <MailOutlined style={{ fontSize: '20px' }} />),
    getItem('Notifications', 'notifications', <MailOutlined style={{ fontSize: '20px' }} />),
    getItem('Cases', '', <UnorderedListOutlined style={{ fontSize: '20px' }} />, [
        getItem('Case', 'casesWithDates', <FileTextOutlined style={{ fontSize: '20px' }} />),
        getItem('Not Attended Cases', 'notAttendedCases', <FileTextOutlined style={{ fontSize: '20px' }} />),
    ], 'subMenu'),
    getItem('Settings', 'settings', <ContainerOutlined />, [
        getItem('Documents', 'documents', <FileOutlined style={{ fontSize: '20px' }} />, [
            getItem('Downloadable Documents', 'downloadableDocuments', <FileOutlined style={{ fontSize: '20px' }} />),
        ], 'subMenu'),
        getItem('Case Jurisdictions', 'caseJurisdictions', <UnorderedListOutlined style={{ fontSize: '20px' }} />),
        getItem('Party Statuses', 'partyStatuses', <UsergroupAddOutlined style={{ fontSize: '20px' }} />),
        getItem('Categories', 'categories', <AppstoreAddOutlined style={{ fontSize: '20px' }} />),
        getItem('Banner Content', 'bannerContent', <FileTextOutlined style={{ fontSize: '20px' }} />),
    ], 'subMenu'),
]

const Sidebar = ({ children, collapsed, toggleCollapsed }) => {
    const navigate = useNavigate();

    const handleMenuClick = (key) => {
        switch (key) {
            case 'dashboard':
                navigate('/');
                break;
            case 'users':
                navigate('/users');
                break;
            case 'lawyers':
                navigate('/lawyers');
                break;
            case 'newcases':
                navigate('/newcases');
                break;
            case 'citizens':
                navigate('/citizens');
                break;
            case 'roles':
                navigate('/roles');
                break;
            case 'reports':
                navigate('/reports');
                break;
            case 'zoneCasesReportColumns':
                navigate('/reports/zoneCasesReportColumns');
                break;
            case 'lawyerCasesReportColumns':
                navigate('/reports/lawyerCasesReportColumns');
                break;
            case 'dailyCasesListToCourt':
                navigate('/reports/dailyCasesListToCourt');
                break;
            case 'allUsersReport':
                navigate('/reports/allUsersReport');
                break;
            case 'reviews':
                navigate('/reviews');
                break;

            case 'messages':
                navigate('/messages');
                break;
            case 'documents':
                navigate('/documents');
                break;
            case 'downloadableDocuments':
                navigate('/downloadable-documents');
                break;
            case 'notifications':
                navigate('/notifications');
                break;
            case 'categories':
                navigate('/settings/categories');
                break;
            case 'cases':
                navigate('/settings/cases');
                break;
            case 'casesWithDates':
                navigate('/settings/casesWithDates');
                break;
            case 'notAttendedCases':
                navigate('/settings/notAttendedCases');
                break;
            case 'caseJurisdictions':
                navigate('/settings/caseJurisdictions');
                break;
            case 'partyStatuses':
                navigate('/settings/partyStatuses');
                break;
            case 'bannerContent':
                navigate('/bannerContent');
                break;

            default:
                break;
        }
    };

    return (
        <Row gutter={[16, 16]} style={{ width: '100%' }}>
            <Col xs={collapsed ? 1 : 3} sm={collapsed ? 1 : 3} md={collapsed ? 1 : 3} lg={collapsed ? 1 : 3} xl={collapsed ? 1 : 3}>
                <Menu
                    style={{
                        height: "100vh",
                        marginRight: "50px",
                        marginTop: '10px',
                        fontSize: ''
                    }}
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    mode="inline"
                    theme="light"
                    inlineCollapsed={collapsed}
                    items={items}
                    onClick={({ key }) => handleMenuClick(key)}
                />
            </Col>
            <Col xs={collapsed ? 23 : 21} sm={collapsed ? 23 : 21} md={collapsed ? 23 : 21} lg={collapsed ? 23 : 21} xl={collapsed ? 23 : 21}>
                <div style={{ width: '100%' }}>
                    {children}
                </div>
            </Col>
        </Row>
    );
};
export default Sidebar;