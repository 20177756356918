let initialState = {
  LoggedIn: false,
  LoginToken: null,
  UserData: null,
  utilsData: null,
  vehicleData: null,
  vehicleTripData: null,
  CaseWithDateDetails: null,
};
function userReducer(state = initialState, action) {
  switch (action.type) {
    case "LOGGED_IN":
      return { ...state, LoggedIn: action.payload };
    case "LOGGED_TOKEN":
      return { ...state, LoginToken: action.payload };
    case "USER_DATA":
      return { ...state, UserData: action.payload };
    case "UTILS_DATA":
      return { ...state, utilsData: action.payload };
    case "CASEWITHDATEDETAILS":
      return { ...state, CaseWithDateDetails: action.payload };
    default:
      return state;
  }
}
export default userReducer;
