import React from 'react';
import { Badge } from 'antd';

export const RoundTextTag = ({ className, value, bgColor, textColor, }) => {
    return (
        <Badge
            className={className}
            color={bgColor ? bgColor : "#565B5C"}
            style={{ backgroundColor: bgColor ? bgColor : '#565B5C', color: textColor ? textColor : "" }}
            count={value}
        />
    );
};

