import { toast } from "react-toastify";
import { ROLES } from "./Constants";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const mapRoleIdToRoleName = (roleId) => {
  switch (roleId) {
    case ROLES.Admin:
      return "Admin";
    case ROLES.Zonal_Manager:
      return "Zonal Manager";
    case ROLES.Citizen:
      return "Citizen";
    case ROLES.Lawyer:
      return "Lawyer";
    case ROLES.Employee:
      return "Employee";
    default:
      return "User";
  }
};

export const toastMessage = (message, isSuccess) => {
  if (isSuccess) {
    toast.success(message);
  } else {
    toast.error(message);
  }
};

export const exportToExcel = (data) => {
  const headers = {
    "Case Title": "caseTitle",
    Status: "status",
    Party: "party",
    Nature: "nature",
    Jurisdiction: "jurisdictionName",
    Location: "location",
    "Created Date": "createdDate",
    "Created By": "createdBy",
  };

  const combinedData = [];

  combinedData.push(
    Object.keys(headers).reduce((acc, key) => {
      acc[key] = key;
      return acc;
    }, {})
  );

  data.forEach((item) => {
    const row = {};
    for (const [header, key] of Object.entries(headers)) {
      row[header] = item[key] || "";
    }
    combinedData.push(row);
  });

  const worksheet = XLSX.utils.json_to_sheet(combinedData, {
    skipHeader: true,
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  XLSX.writeFile(workbook, "data.xlsx");
};

export const exportToPDF = (data) => {
  const doc = new jsPDF();

  const title = "Wakalatnama Report";
  const titleWidth =
    (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) /
    doc.internal.scaleFactor;
  const pageWidth = doc.internal.pageSize.getWidth();
  const titleX = (pageWidth - titleWidth) / 2;

  doc.text(title, titleX, 10);

  console.log("Data passed to PDF:", data);

  if (data && data.length > 0) {
    doc.autoTable({
      head: [
        [
          "Case Title",
          "Status",
          "Party",
          "Nature",
          "Jurisdiction",
          "Location",
          "Created Date",
          "Created By",
        ],
      ],
      body: data.map((row) => [
        row.caseTitle || "N/A",
        row.status || "N/A",
        row.party || "N/A",
        row.nature || "N/A",
        row.jurisdictionName || "N/A",
        row.location || "N/A",
        row.createdDate ? new Date(row.createdDate).toLocaleString() : "N/A",
        row.createdBy || "N/A",
      ]),
    });
  } else {
    console.error("No data available for PDF export");
  }

  doc.save("report.pdf");
};
