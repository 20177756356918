import React, { useEffect, useState } from 'react'
import MyTable from '../../components/MyTable';
import { createBannerContent, deleteBannerContent, getBannerContent, getRoles, } from '../../Utils/ApiManager';
import { Button, Modal, Form, Input, DatePicker, Select } from 'antd';
import moment from 'moment';
import { GenericModal } from '../../components/GenericModal';
import { Spinner } from '../../components/Spinner';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ROLES } from '../../Utils/Constants';
import { DeleteOutlined } from '@ant-design/icons';

const { Item } = Form;
const { Option } = Select;


export const BannerContent = () => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [userRoles, setUserRoles] = useState([])
    const userDataRedux = useSelector(state => state.UserData)

    const [form] = Form.useForm();



    useEffect(() => {
        if (userDataRedux?.roles[0]) {

            getData()
        }
        getUserRoles()
    }, [])

    const getData = async () => {
        setIsLoading(true)
        try {
            const response = await getBannerContent(`RoleId=0`)
            console.log("response", response?.data?.data)
            setData(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }

    const getUserRoles = async () => {
        setIsLoading(true)
        try {
            const response = await getRoles()
            if (response?.data?.data) {
                setUserRoles(response?.data?.data)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }

    const showAddModal = () => {
        setIsAddModalVisible(true);
        form.resetFields(); // Reset form fields when opening the modal
    };

    const handleAddModalOk = () => {

        form
            .validateFields()
            .then(async (values) => {
                const payload = {
                    "id": 0,
                    "content": values.content,
                    "createdDate": new Date(),
                    "expDate": values.expiryDate,
                    "roleId": values.role
                }
                await createBannerContent(payload)
                setIsAddModalVisible(false);
                getData()
                toast.success("Record added successfully")
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    const handleAddModalCancel = () => {
        setIsAddModalVisible(false);
    };

    const showEditModal = (record) => {
        setSelectedRecord(record);
        form.setFieldsValue(record);
        setIsEditModalVisible(true);
    };

    const handleEditModalOk = () => {
        form
            .validateFields()
            .then((values) => {
                setIsEditModalVisible(false);
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    const handleEditModalCancel = () => {
        setIsEditModalVisible(false);
    };


    const handleDelete = (record) => {
        setShowModal(true)
        setSelectedRecord(record);
    };

    const onNoToggleModal = () => {
        setShowModal(false)
        setSelectedRecord(null);
    }

    const onYesDeleteRecord = async () => {
        setIsLoadingDelete(true)
        try {
            await deleteBannerContent(selectedRecord.id)
            getData()
            setIsLoadingDelete(false)
            onNoToggleModal()
            toast.success("Record delted successfully")
        } catch (error) {
            setIsLoadingDelete(false)
        }
    }


    const getRoleName = (roleId) => {
        switch (roleId) {
            case ROLES.Admin:
                return 'Admin';
            case ROLES.Zonal_Manager:
                return 'Zonal Manager';
            case ROLES.Citizen:
                return 'Citizen';
            case ROLES.Lawyer:
                return 'Lawyer';
            case ROLES.Employee:
                return 'Employee';
            default:
                return 'All';
        }
    };

    const tableColumns = [
        {
            title: 'Created For Role',
            dataIndex: 'role',
            key: 'role',
            render: (_, record) => {
                return getRoleName(record.roleId)
            },
        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
        },

        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (createdDate) => moment(createdDate).local().format('lll'),
        },
        {
            title: 'Expiry',
            dataIndex: 'expDate',
            key: 'expDate',
            render: (expDate) => moment(expDate).local().format('lll'),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div>
                    <Button danger onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div>
            {
                isLoadingDelete && <Spinner />
            }
            <GenericModal
                visible={showModal}
                title=" "
                onCancel={onNoToggleModal}
                footer={false}
                width={'25rem'}
                content={<div className='p-4'>
                    <h5 className=' text-center'>Confirmation</h5>
                    <h6 className='text-center mt-4'>Are you sure, you want to remove this record?</h6>
                    <div className='d-flex justify-content-center mt-5'>
                        <Button
                            color='secondary'
                            onClick={onNoToggleModal}
                            style={{ width: '100px' }}
                        >No</Button>
                        <Button
                            type='primary'
                            className='ms-4'
                            style={{ width: '100px' }}
                            onClick={onYesDeleteRecord}
                        >Yes</Button>
                    </div>
                </div>}
            />
            <MyTable
                componentHeader={
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <h4>Banner Content</h4>
                        <Button type="primary" onClick={showAddModal} style={{ marginBottom: '16px' }}>
                            Add
                        </Button>
                    </div>
                }
                columns={tableColumns}
                data={data}
                isLoading={isLoading}
            />

            <Modal
                title="Add Content"
                visible={isAddModalVisible}
                onOk={handleAddModalOk}
                onCancel={handleAddModalCancel}
            >
                <Form form={form}>

                    <Item label="Role" name="role" rules={[{ required: true, message: 'Please select a Role' }]}>
                        <Select placeholder="Select a Role for">
                            {userRoles.filter(role => role.name === "Citizen")?.map(role => {
                                return <Option value={role.id}>{role.name}</Option>
                            })}
                        </Select>
                    </Item>


                    <Item
                        label="Content"
                        name="content"
                        rules={[
                            { required: true, message: 'Please enter content' },
                            { max: 50, message: 'Content must be at least 50 characters' }
                        ]}
                    >
                        <Input.TextArea />
                    </Item>



                    <Item
                        label="Expiry Date"
                        name="expiryDate"
                        rules={[
                            { required: true, message: 'Please select an expiry date' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || value.isAfter(moment())) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Expiry date must be later than the current date and time'));
                                },
                            }),
                        ]}
                    >
                        <DatePicker showTime />
                    </Item>



                </Form>
            </Modal>

            <Modal
                title="Edit "
                visible={isEditModalVisible}
                onOk={handleEditModalOk}
                onCancel={handleEditModalCancel}
            >
                <Form form={form}>
                    <Item label="Name" name="name" rules={[{ required: true, message: 'Please enter a name' }]}>
                        <Input />
                    </Item>
                    <Item label="Age" name="age" rules={[{ required: true, message: 'Please enter an age' }]}>
                        <Input type="number" />
                    </Item>
                    <Item label="Address" name="address" rules={[{ required: true, message: 'Please enter an address' }]}>
                        <Input />
                    </Item>
                    <Item label="Tags" name="tags">
                        <Input />
                    </Item>
                </Form>
            </Modal>
        </div>
    );
};
