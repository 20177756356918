import React, { useEffect, useState } from 'react'
import MyTable from '../../components/MyTable';
import { getCasesReports, getCategories, } from '../../Utils/ApiManager';
import { Button, Col, Form, Row, Select, Tabs } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { exportToExcel, exportToPDF } from '../../Utils/Utils';

const { Item } = Form;
const { Option } = Select
const { TabPane } = Tabs;

export const ZoneCasesReportColumns = () => {
    const [data, setData] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [activeTab, setActiveTab] = useState('allZoneCases');
    const [requestBody, setRequestBody] = useState({
        "caseTitle": "",
        "party": "",
        "caseLegalStatusId": null,
        "createdDate": null,
        "createdBy": "",
        "cityId": null,
        "jurisdictionName": "",
        "categoryId": null
    })

    const navigate = useNavigate()

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    useEffect(() => {
        getCategoryList()
        getData()
    }, [activeTab])

    const getData = async () => {
        setIsLoading(true)

        try {
            const response = await getCasesReports(requestBody)
            setData(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }


    const getCategoryList = async () => {
        setIsLoading(true)
        try {
            const response = await getCategories()
            setCategoryList(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    const handleViewDetails = (record) => {
        navigate(`/newcases/details?caseId=${record.id}`)
    };

    const tableColumns = [
        {
            title: 'Title',
            dataIndex: 'caseTitle',
            key: 'caseTitle',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Party',
            dataIndex: 'party',
            key: 'party',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Category',
            dataIndex: 'nature',
            key: 'nature',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Jurisdiction Name',
            dataIndex: 'jurisdictionName',
            key: 'jurisdictionName',
            render: (text) => (text),
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            render: (text) => (text),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (createdDate) => (
                <span>{moment(createdDate).local().format('LLL')}</span>
            ),
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
        },
    ];



    const statusOptions = [
        { label: "Case Institution/ Diary", value: 1 },
        { label: "Summon to defendant/s", value: 2 },
        { label: "Submission of Written Statement by Defendant/s", value: 3 },
        { label: "Framing of issues", value: 4 },
        { label: "Evidence recorded by Plaintiff/s", value: 5 },
        { label: "Evidence Recorded by Defendant/s", value: 6 },
        { label: "Final Arguments", value: 7 },
        { label: "Judgement & Decree reserved or announced", value: 8 }
    ];

    const handleSearch = () => {
        getData()
    }

    const clearSearch = () => {
        setRequestBody({
            "caseTitle": "",
            "party": "",
            "caseLegalStatusId": null,
            "createdDate": null,
            "createdBy": "",
            "cityId": null,
            "jurisdictionName": "",
            "categoryId": null
        })
        setTimeout(() => {
            getData()
        }, 1000);
    }
    return (
        <div>
            <h4 className='mb-4'>Zone Cases Report</h4>

            <MyTable

                componentHeader={
                    <>
                        <Row gutter={16} align="middle">
                            <Col span={6}>
                                <Form layout="vertical">
                                    <Item label="Select Location" name="location">
                                        <Select value={requestBody.cityId} onChange={(value) => { setRequestBody((prev) => ({ ...prev, cityId: value })) }}>
                                            <Option value="Islamabad">Islamabad</Option>
                                        </Select>
                                    </Item>
                                </Form>
                            </Col>
                            <Col span={6}>
                                <Form layout="vertical">
                                    <Item label="Select Category" name="nature">
                                        <Select value={requestBody.categoryId} onChange={(value) => { setRequestBody((prev) => ({ ...prev, categoryId: value })) }}>
                                            {categoryList.map(option => (
                                                <Option key={option.id} value={option.id}>{option.categoryName}</Option>
                                            ))}
                                        </Select>
                                    </Item>
                                </Form>
                            </Col>
                            <Col span={6}>
                                <Form layout="vertical">
                                    <Item label="Legal Case Status Id" name="status">
                                        <Select value={requestBody.caseLegalStatusId} onChange={(value) => { setRequestBody((prev) => ({ ...prev, caseLegalStatusId: value })) }}>
                                            {statusOptions.map(option => (
                                                <Option key={option.value} value={option.value}>{option.label}</Option>
                                            ))}
                                        </Select>
                                    </Item>
                                </Form>
                            </Col>
                            <Col span={3}>
                                <Button className='me-2' style={{ width: '100%' }} type="primary" onClick={handleSearch}>Search</Button>
                            </Col>
                            <Col span={3}>
                                <Button style={{ width: '100%', justifyContent: 'flex-end' }} type="primary" onClick={clearSearch}>Clear Filter</Button>
                            </Col>
                        </Row>
                        <div
                            style={{
                                justifyContent: 'end'
                            }} className='d-flex'>
                            <Button className='me-2' type="primary" icon={<DownloadOutlined />} onClick={() => exportToPDF(data)}> PDF</Button>
                            <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportToExcel(data)}> Excel</Button>
                        </div>
                        <Tabs activeKey={activeTab} onChange={handleTabChange}>
                            <TabPane tab="All Zone Cases Report" key="allZoneCases">
                            </TabPane>
                        </Tabs>
                    </>
                }
                columns={tableColumns}
                data={data}
                isLoading={isLoading}
            />

        </div>
    );
};
