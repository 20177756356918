import React from 'react'
import { Steps, Button, Form, Select, Modal, Row, Col, Typography, Card } from 'antd';
const { Text, Title } = Typography;

export const StepPayments = () => {
    return (
        <>
            <Card
                className='mt-3'
                style={{
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    borderRadius: '8px',
                    padding: '10px',
                }}
            >
                <Title level={4}>Payment Details</Title>
                <div >
                    <div>
                        <Text strong>Payment ID:</Text>
                        <Text> #234324234</Text>
                    </div>
                    <div>
                        <Text strong>Amount:</Text>
                        <Text> 100$</Text>
                    </div>
                    <div>
                        <Text strong>Account ID:</Text>
                        <Text> #12312312312312312</Text>
                    </div>
                    <div>
                        <Text strong>Account Title:</Text>
                        <Text> Jhon Doe</Text>
                    </div>
                </div>
            </Card>
        </>
    )
}
