import React from 'react'
import { NewCasesTable } from './NewCasesTable'

export const NewCases = () => {
    return (
        <div className=''>
            <NewCasesTable />
        </div>
    )
}
