import React, { useState } from 'react';
import { Steps, Button, Select, Row, Col } from 'antd';
import { Card, Typography, } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import download from 'downloadjs';

const { Text, Title } = Typography;

const { Step } = Steps;
const { Option } = Select;

export const StepDetails = ({
    caseDetails,
    userDetails
}) => {

    const handleFileDownload = (fileUrl) => {
        // Extract the filename from the URL (last part of the URL)
        const fileName = fileUrl.split('/').pop();

        // Fetch the file and trigger download
        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => download(blob, fileName))
            .catch(err => console.error('Error downloading the file:', err));
    };


    return (
        <>
            <Row gutter={[16, 16]} justify="space-between">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card style={{
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        borderRadius: '8px',
                        padding: '10px',
                    }}>
                        <Title level={5}>Case Details</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>ID:</Text>
                                    <Text strong> {caseDetails?.caseId}</Text>
                                </div>
                                <div>
                                    <Text>Title:</Text>
                                    <Text strong> {caseDetails?.caseTitle}</Text>
                                </div>
                                <div>
                                    <Text>Number:</Text>
                                    <Text strong> {caseDetails?.caseNumber}</Text>
                                </div>
                                <div>
                                    <Text>Status:</Text>
                                    <Text strong> {caseDetails?.caseStatus}</Text>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>Placing:</Text>
                                    <Text strong> {caseDetails?.casePlacing}</Text>
                                </div>
                                <div>
                                    <Text>Created Date:</Text>
                                    <Text strong> {new Date(caseDetails?.createdDate).toLocaleString()}</Text>
                                </div>
                                <div>
                                    <Text>Legal Status:</Text>
                                    <Text strong> {caseDetails?.legalStatus}</Text>
                                </div>
                            </Col>
                        </Row>
                        <div className='mt-3'>
                            Files:

                            {caseDetails?.caseFiles && caseDetails?.caseFiles.length > 0 ? caseDetails?.caseFiles.map((file, key) => {
                                return <div key={key + file.docId}>
                                    <p className='mb-0'>
                                        <Button
                                            type="link"
                                            icon={<PaperClipOutlined />}
                                            href={file.docUrl}
                                            onClick={() => handleFileDownload(file.docUrl)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {file.docName}
                                        </Button>
                                    </p>
                                </div>
                            })
                                :
                                " No files attached..."
                            }
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card style={{
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        borderRadius: '8px',
                        padding: '10px',
                    }}>

                        <Title level={5}>User Information</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>CNIC:</Text>
                                    <Text strong> {userDetails?.cnicNo}</Text>
                                </div>
                                <div>
                                    <Text>Contact Number:</Text>
                                    <Text strong> {userDetails?.contactNumber}</Text>
                                </div>
                                <div>
                                    <Text>Current Address:</Text>
                                    <Text strong> {userDetails?.currAddress}</Text>
                                </div>
                                <div>
                                    <Text>Email:</Text>
                                    <Text strong> {userDetails?.email}</Text>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>

                                <div>
                                    <Text>Full Name:</Text>
                                    <Text strong> {userDetails?.fullName}</Text>
                                </div>
                                <div>
                                    <Text>Permanent Address:</Text>
                                    <Text strong> {userDetails?.permAddress}</Text>
                                </div>
                                <div>
                                    <Text>User ID:</Text>
                                    <Text strong> {userDetails?.userId}</Text>
                                </div>
                            </Col>
                        </Row>

                    </Card>
                </Col>

            </Row>
        </>
    )
}
