import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
    { name: 'January', Pending: 40, Resolved: 24, "New Cases": 34 },
    { name: 'February', Pending: 30, Resolved: 13, "New Cases": 33 },
    { name: 'March', Pending: 20, Resolved: 98, "New Cases": 82 },
    { name: 'April', Pending: 27, Resolved: 39, "New Cases": 39 },
    { name: 'May', Pending: 18, Resolved: 48, "New Cases": 48 },
    { name: 'June', Pending: 23, Resolved: 38, "New Cases": 22 },
    { name: 'July', Pending: 34, Resolved: 43, "New Cases": 12 },
    { name: 'August', Pending: 44, Resolved: 22, "New Cases": 22 },
    { name: 'September', Pending: 20, Resolved: 34, "New Cases": 33 },
    { name: 'October', Pending: 26, Resolved: 44, "New Cases": 44 },
    { name: 'November', Pending: 37, Resolved: 23, "New Cases": 23 },
    { name: 'December', Pending: 34, Resolved: 45, "New Cases": 12 },
];

export const LineChartComponent = () => {
    return (
        <div>
            <LineChart
                width={600}
                height={300}
                data={data}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Pending" stroke="#8884d8" />
                <Line type="monotone" dataKey="Resolved" stroke="#82ca9d" />
                <Line type="monotone" dataKey="New Cases" stroke="#11aa45" />
            </LineChart>
        </div>
    );
}
