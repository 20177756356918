import React, { useEffect, useState } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { Dashboard } from '../view/Dashboard/Dashboard';
import { Users } from '../view/Users/Users';
import { MainLayout } from '../templates/MainLayout';
import { Login } from '../view/authentication/Login';
import { Roles } from '../view/Roles/Roles';
import { Lawyers } from '../view/Lawyers/Lawyers';
import { Reviews } from '../view/Review/Reviews';
import { Categories } from '../view/Settings/Categories/Categories';
import { Cases } from '../view/Settings/Cases/Cases';
import { PartyStatus } from '../view/Settings/PartyStatus/PartyStatus';
import { CaseJurisdictions } from '../view/Settings/CaseJurisdictions/CaseJurisdictions';
import { Documents } from '../view/Document/Documents';
import { Notifications } from '../view/Notifications/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Citizens } from '../view/Citizens/Citizens';
import { NewCases } from '../view/NewCases/NewCases';
import { NewCaseDetails } from '../view/NewCases/NewCaseDetails/NewCaseDetails';
import { userData } from '../redux/actions';
import { Reports } from '../view/Reports/Report';
import { ZoneCasesReportColumns } from '../view/Reports/ZoneCasesReportColumns';
import { LawyerCasesReportColumns } from '../view/Reports/LawyerCasesReportColumns';
import { DailyCasesListToCourt } from '../view/Reports/DailyCasesListToCourt';
import { AllUsersReport } from '../view/Reports/AllUsersReport';
import { LawyerDetails } from '../view/Lawyers/LawyerDetials';
import { Messages } from '../view/Messages/Messages';
import { DownloadableDocument } from '../view/Document/DownloadableDocument/DownloadableDocument';
import { BannerContent } from '../view/BannerContent/BannerContent';
import { CasesWithDates } from '../view/Settings/CasesWithDates/CasesWithDates';
import { CasesWithDatesDetails } from '../view/Settings/CasesWithDates/CasesWithDatesDetails';
import { CaseDateDetails } from '../view/Settings/CasesWithDates/CaseDateDetails';
import { Profile } from '../view/Dashboard/Profile/Profile';
import { PrivateChat } from '../view/Messages/PrivateChat';
import { NotAttendedCases } from '../view/Settings/NotAttendedCases/NotAttendedCases';
import useVisibilityChange from '../Utils/useVisibilityChange';
import { setupNotifications } from '../Utils/firebaseConfig';


const componentRoutes = [
    { path: '/', element: <Dashboard />, role: "Admin" },
    { path: '/dashboard', element: <Dashboard />, role: "Admin" },
    { path: "/users", element: <Users />, role: "Admin" },
    { path: "/roles", element: <Roles />, role: "Admin" },
    { path: "/reports/zoneCasesReportColumns", element: <ZoneCasesReportColumns />, role: "Admin" },
    { path: "/reports/lawyerCasesReportColumns", element: <LawyerCasesReportColumns />, role: "Admin" },
    { path: "/reports/dailyCasesListToCourt", element: <DailyCasesListToCourt />, role: "Admin" },
    { path: "/reports/allUsersReport", element: <AllUsersReport />, role: "Admin" },
    { path: "/lawyers", element: <Lawyers />, role: "Admin" },
    { path: "/lawyers/details", element: <LawyerDetails />, role: "Admin" },
    { path: "/newcases", element: <NewCases />, role: "Admin" },
    { path: "/newcases/details", element: <NewCaseDetails />, role: "Admin" },
    { path: "/citizens", element: <Citizens />, role: "Admin" },
    { path: "/reviews", element: <Reviews />, role: "Admin" },
    { path: "/documents", element: <Documents />, role: "Admin" },
    { path: "/downloadable-documents", element: <DownloadableDocument />, role: "Admin" },
    { path: "/notifications", element: <Notifications />, role: "Admin" },
    { path: "/messages", element: <Messages />, role: "Admin" },
    { path: "/private-chat", element: <PrivateChat />, role: "Admin" },
    { path: "/settings/categories", element: <Categories />, role: "Admin" },
    { path: "/settings/cases", element: <Cases />, role: "Admin" },
    { path: "/settings/casesWithDates", element: <CasesWithDates />, role: "Admin" },
    { path: "/settings/casesWithDates/DateDetails", element: <CaseDateDetails />, role: "Admin" },
    { path: "/settings/casesWithDates/details", element: <CasesWithDatesDetails />, role: "Admin" },
    { path: "/settings/notAttendedCases", element: <NotAttendedCases />, role: "Admin" },
    { path: "/settings/partyStatuses", element: <PartyStatus />, role: "Admin" },
    { path: "/users", element: <Users />, role: "MANAGER" },
    { path: "/bannerContent", element: <BannerContent />, role: "Admin" },
    { path: "/settings/caseJurisdictions", element: <CaseJurisdictions />, role: "Admin" },
    { path: "/profile", element: <Profile />, role: "Admin" },
    { path: "/users", element: <Users />, role: "MANAGER" },
    { path: '/', element: <Login />, role: "PUBLIC" }
];


const MainRouter = () => {
    const loggedInStore = useSelector((state) => state.LoggedIn);
    const userDataStore = useSelector((state) => state.UserData);
    const localStorageLoggedIn = localStorage.getItem('loggedIn')
    const localStorageUserRole = localStorage.getItem('userRole')
    const localStorageUserData = localStorage.getItem('userData')
    const [isLoggedInState, setIsLoggedInState] = useState(false)
    const [userRole, setUserRole] = useState('')

    const dispatch = useDispatch()
    const isForeground = useVisibilityChange();


    useEffect(() => {
        if (localStorageLoggedIn || loggedInStore) {
            setIsLoggedInState(true)
        }
    }, [localStorageLoggedIn, loggedInStore])

    useEffect(() => {
        if (localStorageUserRole || userDataStore) {
            setUserRole(localStorageUserRole)
        }
    }, [localStorageUserRole, userDataStore])


    useEffect(() => {
        if (localStorageUserData) {
            dispatch(userData(JSON.parse(localStorageUserData)))
        }
    }, [localStorageUserData])



    useEffect(() => {
        setupNotifications(() => {
            if (isForeground) {
            } else {
            }
        });
    }, [localStorageUserData]);


    return (
        <HashRouter>
            {isLoggedInState ?
                <MainLayout>
                    <Routes>
                        {componentRoutes.filter(route => route.role === userRole).map((route, key) => {
                            return <Route key={key} path={route.path} element={route.element} />
                        })}
                    </Routes>
                </MainLayout>
                :
                <Routes>
                    <Route path='/' element={<Login />} />
                </Routes>
            }
        </HashRouter>
    );
};

export default MainRouter;
