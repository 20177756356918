import axios from "axios";
import { toast } from "react-toastify";

export const ApiCall = async (path, method, payload, headers) => {
  let JWT = localStorage.getItem("LoginToken");

  let config = {
    method: method,
    url: path,
    data: payload,
    headers: {
      "Content-Type": headers ? headers : "application/json",
      Authorization: `Bearer ${JSON.parse(JWT)}`,
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios(config);
      if (
        response.data.responseCode === 3000 ||
        response.data.responseCode === 3003 ||
        response.data.responseCode === 3005 ||
        response.data.responseCode === 3007
      ) {
        reject(response.data);
      } else {
        resolve(response);
      }
    } catch (err) {
      if (
        err &&
        err.response &&
        (err.response.data.responseCode === 401 || err.response.status === 401)
      ) {
        localStorage.clear();
        window.location.href = "/";
        return;
      }
      if (err?.code === "ERR_NETWORK") {
        toast.error("Something went wrong please try again");
        return;
      }
      reject(err);
    }
  });
};
