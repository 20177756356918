import React, { useEffect, useState } from 'react'
import MyTable from '../../../components/MyTable';
import { deleteDocument, deleteFile, getDownloadableDocument, getFiles, getReviews, uploadFile, uploadUserDocument } from '../../../Utils/ApiManager';
import { Tag, Button, Modal, Form, Input, Select, Upload } from 'antd';
import { DownloadOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { GenericModal } from '../../../components/GenericModal';
import { Spinner } from '../../../components/Spinner';
import { EDocumentType } from '../../../Utils/Constants';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const { Item } = Form;
const { Option } = Select;

export const DownloadableDocument = () => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [file, setFile] = useState(null);
    const [docType, setDocType] = useState(null);

    const userDataRedux = useSelector(state => state.UserData)

    const [form] = Form.useForm();


    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        setIsLoading(true)
        try {
            const response = await getDownloadableDocument("0")
            console.log("response", response?.data?.data)
            setData(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }


    const showAddModal = () => {
        setIsAddModalVisible(true);
        form.resetFields(); // Reset form fields when opening the modal
    };

    const handleLegalFileChange = (info) => {
        let document = [...info.fileList];
        if (info.file.status === 'done') {
            const reader = new FileReader();
            reader.onload = (e) => {
            };
            reader.readAsDataURL(info.file.originFileObj);
        }
        setFile(document);
    };


    const beforeUpload = (file) => {
        return true;
    };


    const handleAddModalOk = async () => {
        setIsLoading(true)

        const payload = {
            "documentId": 0,
            "userId": userDataRedux?.userId,
            "docName": "",
            "docPath": "",
            "docExtension": "",
            "docTypeId": 0,
            "isUploaded": true
        };

        const formData = new FormData();
        formData.append("file", file[0]?.originFileObj);

        try {
            const response = await uploadFile(formData, "?docType=1");
            payload.docPath = response?.url;

            await uploadUserDocument(payload);
            setIsAddModalVisible(false);
            toast.success("File uploaded successfully");
            setIsLoading(false)
            setFile([]);
            setDocType(null)
        } catch (error) {
            console.error('File upload failed:', error);
            toast.error("File upload failed: " + (error?.message || "Unknown error"));
            setIsLoading(false)
        }
    };


    const handleAddModalCancel = () => {
        setIsAddModalVisible(false);
    };

    const showEditModal = (record) => {
        setSelectedRecord(record);
        form.setFieldsValue(record);
        setIsEditModalVisible(true);
    };

    const handleEditModalOk = () => {
        form
            .validateFields()
            .then((values) => {
                setIsEditModalVisible(false);
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    const handleEditModalCancel = () => {
        setIsEditModalVisible(false);
    };


    const handleDelete = (record) => {
        setShowModal(true)
        setSelectedRecord(record);
    };

    const onNoToggleModal = () => {
        setShowModal(false)
        setSelectedRecord(null);
    }

    const onYesDeleteRecord = async () => {
        setIsLoadingDelete(true)
        try {
            await deleteDocument(`?docId=${selectedRecord?.id ?? ""}&docType=${selectedRecord?.docType ?? ""}`)
            getData()
            setIsLoadingDelete(false)
            onNoToggleModal()
            toast.success("File deleted successfully")
        } catch (error) {
            toast.error(error?.message);
            setIsLoadingDelete(false)
        }
    }

    const tableColumns = [
        {
            title: 'Document Id',
            dataIndex: 'documentId',
            key: 'documentId',
        },
        {
            title: 'User Id',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: 'Document Name',
            dataIndex: 'docName',
            key: 'docName',
        },
        {
            title: 'Document Path',
            dataIndex: 'docPath',
            key: 'docPath',
        },
        {
            title: 'Document Extension',
            dataIndex: 'docExtension',
            key: 'docExtension',
        },
        {
            title: 'Document Type Id',
            dataIndex: 'docTypeId',
            key: 'docTypeId',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div size="middle">
                    <Button className='me-2' onClick={() => showEditModal(record)}>
                        <DownloadOutlined />
                    </Button>
                    <Button danger onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </div>
            ),
        },
    ];


    return (
        <div>
            {
                (isLoading || isLoadingDelete) && <Spinner />
            }
            <GenericModal
                visible={showModal}
                title=" "
                onCancel={onNoToggleModal}
                footer={false}
                width={'25rem'}
                content={<div className='p-4'>
                    <h5 className=' text-center'>Confirmation</h5>
                    <h6 className='text-center mt-4'>Are you sure, you want to remove this record?</h6>
                    <div className='d-flex justify-content-center mt-5'>
                        <Button
                            color='secondary'
                            onClick={onNoToggleModal}
                            style={{ width: '100px' }}
                        >No</Button>
                        <Button
                            type='primary'
                            className='ms-4'
                            style={{ width: '100px' }}
                            onClick={onYesDeleteRecord}
                        >Yes</Button>
                    </div>
                </div>}
            />
            <MyTable
                componentHeader={
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <h4>Downloadable Documents</h4>
                        <Button type="primary" onClick={showAddModal} style={{ marginBottom: '16px' }}>
                            Upload
                        </Button>
                    </div>
                }
                columns={tableColumns}
                data={data}
                isLoading={isLoading}
            />

            <Modal
                title="Upload Document"
                visible={isAddModalVisible}
                onOk={handleAddModalOk}
                onCancel={handleAddModalCancel}
            >
                <div>
                    <Item label="Document Type" name="docTypeId" rules={[{ required: true, message: 'Please select a document type' }]}>
                        <Select placeholder="Select a document type"
                            onChange={(e) => {
                                setDocType(e)
                            }}>
                            {
                                EDocumentType.map((type, k) => {
                                    return <Option key={k} value={type.value}>{type.name}</Option>
                                })
                            }
                        </Select>
                    </Item>
                    <Item label="Upload Document" name="docFile" rules={[{ required: true, message: 'Please upload a document' }]}>
                        <Upload
                            className='mt-4'
                            fileList={file}
                            beforeUpload={beforeUpload}
                            onChange={handleLegalFileChange}
                            listType="picture-card"
                        >
                            {
                                file?.length > 0 ? "" :
                                    <UploadOutlined />
                            }

                        </Upload>
                    </Item>
                </div>
            </Modal>

            {/* <Modal
                title="Edit "
                visible={isEditModalVisible}
                onOk={handleEditModalOk}
                onCancel={handleEditModalCancel}
            >
                <Form form={form}>
                    <Item label="Name" name="name" rules={[{ required: true, message: 'Please enter a name' }]}>
                        <Input />
                    </Item>
                    <Item label="Age" name="age" rules={[{ required: true, message: 'Please enter an age' }]}>
                        <Input type="number" />
                    </Item>
                    <Item label="Address" name="address" rules={[{ required: true, message: 'Please enter an address' }]}>
                        <Input />
                    </Item>
                    <Item label="Tags" name="tags">
                        <Input />
                    </Item>
                </Form>
            </Modal> */}
        </div>
    );
};
