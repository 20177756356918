import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Divider, Input, Button, Upload, } from 'antd';
import { ChatUserList } from './ChatUserList';
import { RoundTextTag } from '../../components/RoundTextTag';
import { BASE_URL, getChatsForUser, getPrivateChat, markMessageRead, sendMessage, uploadFile } from '../../Utils/ApiManager';
import { useSelector } from 'react-redux';
import { Spinner } from '../../components/Spinner';
import "./Messages.css"
import { HubConnectionBuilder } from '@microsoft/signalr';
import { MessagesList } from './MessagesList';
import { RightOutlined, UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { EDocumentType } from '../../Utils/Constants';
import { toast } from 'react-toastify';

export const Messages = () => {
    const [selectedUserChat, setSelectedUserChat] = useState();
    const [isMesssageModal, setIsMessageModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [myChats, setMyChats] = useState([])
    const [privateMessage, setPrivateMessage] = useState([])
    const userDataRedux = useSelector(state => state.UserData)
    const connection = useRef(null);
    const navigate = useNavigate()
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        // Create a connection to the SignalR hub
        connection.current = new HubConnectionBuilder()
            .withUrl(BASE_URL + "/chat-hub", {
                accessTokenFactory: () => {
                    const token = localStorage.getItem("LoginToken");
                    return JSON.parse(token);
                }
            })
            .withAutomaticReconnect()
            .build();

        // Start the connection
        connection.current.start().then(() => {
            console.log('Connected to SignalR hub');

            // Listen for messages from the SignalR hub
            connection.current.on('DirectMessage', (incomingMessage) => {
                console.log("incomingMessage", incomingMessage)
                console.log("privateMessage", privateMessage)
                setPrivateMessage(prevMessages => [...prevMessages, incomingMessage]);
            });
        });

        // Cleanup on unmount
        return () => {
            connection.current.stop();
        };
    }, [userDataRedux?.userId]);


    useEffect(() => {
        getChats()
    }, [])

    useEffect(() => {
        if (selectedUserChat) {
            readUserChat(selectedUserChat)
            getPrivateChats(selectedUserChat?.userId)
        }
    }, [selectedUserChat])

    const getChats = async () => {
        setIsLoading(true)
        try {
            const response = await getChatsForUser(`?userId=${userDataRedux?.userId}`)
            console.log("response", response?.data?.data)
            setMyChats(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }

    const readUserChat = async (sendObject) => {
        setIsLoading(true)
        try {
            await markMessageRead(`?SenderId=${sendObject?.userId}&RecieverId=${userDataRedux?.userId}`)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }


    const getPrivateChats = async (userId) => {
        setIsLoading(true)
        try {
            const response = await getPrivateChat({
                "fromUserId": userDataRedux?.userId,
                "toUserId": userId,
                "id": 0,
                "content": "string",
                "isRead": true,
                "parentId": 0,
                "dateTime": "2024-07-26T05:34:05.165Z"
            })
            console.log("response", response?.data?.data)
            setPrivateMessage(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }


    const handleSelectedUserChat = (userId) => {
        setSelectedUserChat(userId);

    };


    const onCloseMessageModal = () => {
        setIsMessageModal(false)
    }


    const handleSendMessage = async (msgParam) => {
        const msg = msgParam ? msgParam : message
        if (msg.trim() !== '') {
            try {
                await sendMessage({
                    "id": 0,
                    "content": msg,
                    "fromUserId": userDataRedux?.userId,
                    "toUserId": selectedUserChat?.userId,
                    "isRead": false,
                    "parentId": 0,
                    "dateTime": new Date()
                })
                setIsLoading(false)
                setPrivateMessage([...privateMessage, {
                    "id": 0,
                    "content": msg,
                    "fromUserId": userDataRedux?.userId,
                    "toUserId": selectedUserChat?.userId,
                    "isRead": false,
                    "parentId": 0,
                    "dateTime": new Date()
                }])
            } catch (error) {
                setIsLoading(false)
                console.log("error", error)
            }
            setMessage("")
        }
    };



    const handleFileChange = async ({ fileList }) => {
        if (fileList?.length) {
            setFileList(fileList);
            setIsLoading(true)

            const formData = new FormData();
            formData.append("file", fileList[0]?.originFileObj);

            try {
                const docType = EDocumentType.find(type => type.name === "InMessageDoc").value
                const response = await uploadFile(formData, `?docType=${docType}`);
                if (response?.data?.result) {
                    handleSendMessage(response?.data?.result)
                    setFileList([]);
                }
                setIsLoading(false)

            } catch (error) {
                console.error('File upload failed:', error);
                toast.error("File upload failed: " + (error?.message || "Unknown error"));
                setIsLoading(false)
            }
        } else {
            setFileList([]);
        }
    };

    return (
        <div style={{ textAlign: 'left', minHeight: '90vh' }}>
            {
                isLoading
                && <Spinner />
            }
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                <h5>Messages</h5>
                <p onClick={() => navigate("/private-chat")} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    View User Chat
                    <RightOutlined style={{ marginLeft: '5px' }} />
                </p>            </div>
            <Row gutter={[16, 16]} style={{ width: '100%', marginTop: '20px' }}>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <ChatUserList
                        selectedUserChat={selectedUserChat}
                        handleSelectedUserChat={handleSelectedUserChat}
                        myChats={myChats}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                    {selectedUserChat ? (
                        <Card >
                            <Row align="middle">
                                <Col flex="auto" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className='d-flex'>
                                        <h6 style={{ color: "#7D0A0A", textDecoration: 'underline' }} className='mr-3'>{selectedUserChat?.fullName}</h6>
                                        <RoundTextTag className="ms-2" value={selectedUserChat?.isRead ? "seen" : ""} bgColor={"#00979C26"} textColor={"#00979C"} />
                                        <span style={{ marginLeft: 8, color: 'gray' }}>
                                            {selectedUserChat?.createdDate}
                                        </span>
                                    </div>
                                    <div>
                                        <span style={{ marginRight: 8, color: 'green', fontSize: 20 }}>&#9679;</span>
                                        Online
                                    </div>
                                </Col>
                            </Row>
                            <Divider></Divider>
                            <MessagesList privateMessage={privateMessage} />
                            <div className='d-flex mt-4'
                            >
                                <Input
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    size='large'
                                    placeholder='Type your message here...'
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleSendMessage()
                                        }
                                    }}
                                />
                                <Upload
                                    fileList={fileList}
                                    beforeUpload={() => false}
                                    onChange={handleFileChange}
                                    accept=".pdf,.doc,.docx,.jpg,.png"
                                    className='d-flex'
                                    multiple={false}
                                >
                                    <Button
                                        icon={<UploadOutlined />}
                                        className='ms-2'
                                        size='large'
                                    >
                                    </Button>
                                </Upload>
                                <Button
                                    onClick={handleSendMessage}
                                    type='primary'
                                    size='large'
                                    className='ms-2'
                                    style={{ width: "120px" }}
                                >Send</Button>
                            </div>
                        </Card>
                    )
                        :
                        <Card>
                            <p>Select a user to chat with...</p>
                        </Card>
                    }
                </Col>

            </Row>
        </div>
    );
};
