import React, { useEffect, useState } from 'react'
import MyTable from '../../components/MyTable';
import { deleteRole, getRoles, s } from '../../Utils/ApiManager';
import { Tag, Button, Modal, Form, Input, Row, Col } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { GenericModal } from '../../components/GenericModal';
import { Spinner } from '../../components/Spinner';

const { Item } = Form;


export const Roles = () => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const [form] = Form.useForm();


    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        setIsLoading(true)
        try {
            const response = await getRoles()
            console.log("response", response?.data?.data)
            setData(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }


    const showAddModal = () => {
        setIsAddModalVisible(true);
        form.resetFields(); // Reset form fields when opening the modal
    };

    const handleAddModalOk = () => {
        form
            .validateFields()
            .then((values) => {
                // Handle the form submission logic here
                console.log('Add:', values);
                setIsAddModalVisible(false);
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    const handleAddModalCancel = () => {
        setIsAddModalVisible(false);
    };

    const showEditModal = (record) => {
        setSelectedRecord(record);
        form.setFieldsValue(record); // Set initial values for Edit Roles form
        setIsEditModalVisible(true);
    };

    const handleEditModalOk = () => {
        form
            .validateFields()
            .then((values) => {
                // Handle the form submission logic for editing here
                console.log('Edit:', values);
                setIsEditModalVisible(false);
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    const handleEditModalCancel = () => {
        setIsEditModalVisible(false);
    };

    const handleDelete = (record) => {
        setShowModal(true)
        setSelectedRecord(record);
    };

    const onNoToggleModal = () => {
        setShowModal(false)
        setSelectedRecord(null);
    }

    const onYesDeleteRecord = async () => {
        setIsLoadingDelete(true)
        try {
            await deleteRole(selectedRecord.id)
            getRoles()
            setIsLoadingDelete(false)
            onNoToggleModal()
        } catch (error) {
            setIsLoadingDelete(false)
        }
    }


    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'NormalizedName',
            dataIndex: 'normalizedName',
            key: 'normalizedName',
        },
        {
            title: 'Concurrency Stamp',
            dataIndex: 'concurrencyStamp',
            key: 'concurrencyStamp',
        },

    ];

    return (
        <div>
            {
                isLoadingDelete && <Spinner />
            }
            {/* <GenericModal
                visible={showModal}
                title=" "
                onCancel={onNoToggleModal}
                footer={false}
                width={'25rem'}
                content={<div className='p-4'>
                    <h5 className=' text-center'>Confirmation</h5>
                    <h6 className='text-center mt-4'>Are you sure, you want to remove this record?</h6>
                    <div className='d-flex justify-content-center mt-5'>
                        <Button
                            color='secondary'
                            onClick={onNoToggleModal}
                            style={{ width: '100px' }}
                        >No</Button>
                        <Button
                            type='primary'
                            className='ms-4'
                            style={{ width: '100px' }}
                            onClick={onYesDeleteRecord}
                        >Yes</Button>
                    </div>
                </div>}
            /> */}
            <MyTable
                componentHeader={
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <h4>Roles</h4>
                        {/* <Button type="primary" onClick={showAddModal} style={{ marginBottom: '16px' }}>
                            Add Roles
                        </Button> */}
                    </div>
                }
                columns={tableColumns}
                data={data}
                isLoading={isLoading}
            />

            {/* Add Roles Modal */}
            {/* <Modal
                title="Add Roles"
                visible={isAddModalVisible}
                onOk={handleAddModalOk}
                onCancel={handleAddModalCancel}
                width={"50%"}
            >
                <Form form={form} layout="vertical">
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <Item label="Name" name="name" rules={[{ required: true, message: 'Please enter name' }]}>
                                <Input />
                            </Item>
                            <Item label="Normalized Name" name="normalizedName" rules={[{ required: true, message: 'Please enter normalized name' }]}>
                                <Input />
                            </Item>
                            <Item label="Created By" name="createdBy" rules={[{ required: true, message: 'Please enter created by' }]}>
                                <Input />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item label="Updated By" name="updatedBy" rules={[{ required: true, message: 'Please enter updated by' }]}>
                                <Input />
                            </Item>
                            <Item label="Is Deleted" name="isDeleted" rules={[{ required: true, message: 'Please specify if item is deleted' }]}>
                                <Input />
                            </Item>
                            <Item label="Concurrency Stamp" name="concurrencyStamp">
                                <Input />
                            </Item>
                        </Col>
                        <Col span={8}>

                            <Item label="Update Date" name="updateDate">
                                <Input />
                            </Item>
                            <Item label="Created Date" name="createdDate">
                                <Input />
                            </Item>
                        </Col>
                    </Row>
                </Form>
            </Modal> */}

            {/* Edit Roles Modal */}
            {/* <Modal
                title="Edit Roles"
                visible={isEditModalVisible}
                onOk={handleEditModalOk}
                onCancel={handleEditModalCancel}
            >
                <Form form={form}>
                    <Item label="Name" name="name" rules={[{ required: true, message: 'Please enter a name' }]}>
                        <Input />
                    </Item>
                    <Item label="Age" name="age" rules={[{ required: true, message: 'Please enter an age' }]}>
                        <Input type="number" />
                    </Item>
                    <Item label="Address" name="address" rules={[{ required: true, message: 'Please enter an address' }]}>
                        <Input />
                    </Item>
                    <Item label="Tags" name="tags">
                        <Input />
                    </Item>
                </Form>
            </Modal> */}
        </div>
    );
};
