import React from 'react'
import { Form, Input, Tabs, Row, Col, Select } from 'antd';


const { Item } = Form;
const { Option } = Select
export const EditUser = () => {
    const [form] = Form.useForm();

    return (
        <>
            <Form form={form} layout="vertical" className='pt-4'>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Item label="Full Name" name="fullName" rules={[{ required: true, message: 'Please enter full name' }]}>
                            <Input />
                        </Item>
                        <Item label="CNIC No" name="cnicNo" rules={[{ required: true, message: 'Please enter CNIC number' }]}>
                            <Input />
                        </Item>
                        <Item label="Gender" name="gender" rules={[{ required: true, message: 'Please select gender' }]}>
                            <Input />
                        </Item>
                        <Item label="Email" name="email" rules={[{ required: true, message: 'Please enter email' }]}>
                            <Input />
                        </Item>
                        <Item label="Contact Number" name="contactNumber" rules={[{ required: true, message: 'Please enter contact number' }]}>
                            <Input />
                        </Item>
                        <Item label="Current Address" name="currAddress" rules={[{ required: true, message: 'Please enter current address' }]}>
                            <Input />
                        </Item>
                        <Item label="Overseas Contact Number" name="overseasContactNo">
                            <Input />
                        </Item>
                        <Item label="Office Address" name="officeAddress">
                            <Input />
                        </Item>
                        <Item label="Lower Court Name" name="lCourtName">
                            <Input />
                        </Item>
                        <Item label="Lower Court Location" name="lCourtLocation">
                            <Input />
                        </Item>
                        <Item label="Bar Council" name="barCouncil">
                            <Input />
                        </Item>
                        <Item label="Enrollment Date" name="enrollmentDate">
                            <Input />
                        </Item>
                        <Item label="Total Experience" name="totalExperience">
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item label="Permanent Address" name="permAddress" rules={[{ required: true, message: 'Please enter permanent address' }]}>
                            <Input />
                        </Item>
                        <Item label="City ID" name="cityId" rules={[{ required: true, message: 'Please enter city ID' }]}>
                            <Input />
                        </Item>
                        <Item label="Country ID" name="countryId" rules={[{ required: true, message: 'Please enter country ID' }]}>
                            <Input />
                        </Item>
                        <Item label="Is Overseas" name="isOverseas" rules={[{ required: true, message: 'Please specify if user is overseas' }]}>
                            <Input />
                        </Item>
                        <Item label="NICOP" name="nicop">
                            <Input />
                        </Item>
                        <Item label="Passport ID" name="passportID">
                            <Input />
                        </Item>
                        <Item label="Reside Country ID" name="resideCountryId">
                            <Input />
                        </Item>
                        <Item label="Lower High Court Name" name="lHighCourtName">
                            <Input />
                        </Item>
                        <Item label="Lower High Court Location" name="lHighCourtLocation">
                            <Input />
                        </Item>
                        <Item label="Qualification" name="qualification">
                            <Input />
                        </Item>
                        <Item label="Institute" name="institute">
                            <Input />
                        </Item>
                        <Item label="Areas of Expertise" name="areasOfExpertise">
                            <Input />
                        </Item>
                        <Item label="Areas of Expertise (Other)" name="areasOfExpertiseOrther">
                            <Input />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item label="Is Alert" name="isAlert">
                            <Select>
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                            </Select>
                        </Item>
                        <Item label="Is SMS" name="isSMS">
                            <Select>
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                            </Select>
                        </Item>
                        <Item label="Is Email" name="isEmail">
                            <Select>
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                            </Select>
                        </Item>
                        <Item label="Is Push Alert" name="isPushAlert">
                            <Select>
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                            </Select>
                        </Item>
                        <Item label="Is Create Meeting" name="isCreateMeeting">
                            <Select>
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                            </Select>
                        </Item>
                        <Item label="Is Agreed" name="isAgreed">
                            <Select>
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                            </Select>
                        </Item>
                        <Item label="Is Active" name="isActive">
                            <Select>
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                            </Select>
                        </Item>
                        <Item label="Is Verified" name="isVerified">
                            <Select>
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                            </Select>
                        </Item>
                        <Item label="Is Favourite" name="isFavourite">
                            <Select>
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                            </Select>
                        </Item>

                        <Item label="Rating" name="rating">
                            <Input />
                        </Item>
                        <Item label="Profile Description" name="profileDescription">
                            <Input />
                        </Item>
                        <Item label="Father's Name" name="fatherName">
                            <Input />
                        </Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}
