import React from 'react';
import { Card, Avatar, Descriptions } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

export const Profile = () => {

    const userDataRedux = useSelector(state => state.UserData)

    return (
        <Card style={{ maxWidth: 600, margin: '0 auto', textAlign: 'center', padding: '20px' }}>
            <Avatar
                size={100}
                icon={<UserOutlined />}
                src={userDataRedux?.profilePic}
                style={{ marginBottom: '20px' }}
            />
            <Descriptions title={userDataRedux?.userName} bordered column={1}>
                <Descriptions.Item label="Username">{userDataRedux?.userName}</Descriptions.Item>
                <Descriptions.Item label="Email">{userDataRedux?.email}</Descriptions.Item>
                <Descriptions.Item label="Roles">{userDataRedux?.roles.join(', ')}</Descriptions.Item>
            </Descriptions>
        </Card>
    );
};

