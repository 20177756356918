import React, { Fragment, useEffect, useRef, useState } from 'react';
import userImg from "../media/user-img.png";
import { useNavigate } from "react-router-dom";
import { Bell } from '../media/svgIcons';
import { Button, Divider } from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import logo from "../media/logo.jpeg"
import { useSelector } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import NotificationDropdown from './NotificationDropdown';
import { BASE_URL, getAllNotifications } from '../Utils/ApiManager';
import { HubConnectionBuilder } from '@microsoft/signalr';

const SiteHeader = ({ toggleSidebar, toggleCollapsed, collapsed }) => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.UserData)
    const [notifications, setNotifications] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const connection = useRef(null);

    useEffect(() => {
        // Create a connection to the SignalR hub
        connection.current = new HubConnectionBuilder()
            .withUrl(BASE_URL + "/chat-hub", {
                accessTokenFactory: () => {
                    const token = localStorage.getItem("LoginToken");
                    return JSON.parse(token);
                }
            })
            .withAutomaticReconnect()
            .build();

        // Start the connection
        connection.current.start().then(() => {
            console.log('Connected to SignalR hub');
            connection.current.on('UnReadNotification', (notification) => {
                setNotificationCount(notification);
            });
        });

        // Cleanup on unmount
        return () => {
            connection.current.stop();
        };
    }, [userData?.userId]);



    useEffect(() => {
        getNotificationList()
    }, [])

    const getNotificationList = async () => {
        await getAllNotifications()
            .then((response) => {
                if (response) {
                    const list = [...response?.data?.data]
                    setNotifications(list)
                }
            })
            .catch((error) => {
                console.log("error", error)
            });
    }


    const menu = (
        <Menu
            onClick={(e) => {
                navigate(`/${e.key}`)
            }}
            style={{ width: '150px' }}>
            <Menu.Item key="profile">
                Profile
            </Menu.Item>
            <Menu.Item key="settings">
                Settings
            </Menu.Item>
            <Divider />
            <Menu.Item key="logout" onClick={() => {
                localStorage.clear()
                window.location.href = "/"
            }}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Fragment>

            <div className="site-header-wrapper">
                <div>
                    <Button
                        type=""
                        onClick={toggleCollapsed}
                        style={{
                            marginBottom: 16,
                            marginRight: '20px'
                        }}
                    >
                        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>
                </div>
                <div onClick={() => navigate("/dashboard")} style={{ cursor: 'pointer' }}>
                    <img style={{ width: '80px' }} className='mt-2 mb-2' src={logo} alt={'header-logo'} />
                    <span className="portal-heading ms-2">Wakalatnama</span>
                </div>
                <div className="notifications" >
                    <NotificationDropdown notifications={notifications} notificationCount={notificationCount} />
                    <h6 className="ms-2 me-2 mt-2">Hi, {userData?.userName}</h6>
                    <Dropdown overlay={menu} placement="bottomRight">
                        <img src={userImg} alt="user-img" style={{ cursor: 'pointer' }} />
                    </Dropdown>
                </div>
            </div>
            <section className="mobile-header-wrapper">
                <div className="container">
                    <div className="mobile-header">
                        <div className="mobile-toggle-menu-icon">
                            <span onClick={toggleSidebar} className="me-3">
                                <Menu />
                            </span>
                            <p className="mobile-header-title">Wakalatname</p>
                        </div>
                        <div className="mobile-notification-bar">
                            <span className="me-3">
                                <Bell />
                            </span>
                            <img src={userImg} alt={'user-img'} />
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default SiteHeader;