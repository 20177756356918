import React, { useEffect, useState } from 'react'
import MyTable from '../../components/MyTable';
import { deleteUser, getAllUser, getCitizenDetails, getLawyerList, getRoles, s } from '../../Utils/ApiManager';
import { Tag, Button, Modal, Form, Input, Tabs } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { GenericModal } from '../../components/GenericModal';
import { Spinner } from '../../components/Spinner';
import { ROLES } from '../../Utils/Constants';
import { useSelector } from 'react-redux';
import { toastMessage } from '../../Utils/Utils';
import AddUser from '../Users/AddUser';

const { Item } = Form;

const { TabPane } = Tabs;

export const Citizens = () => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showModal, setShowModal] = useState(false)
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)
    const [activeTab, setActiveTab] = useState('allCitizens');
    const userData = useSelector(state => state.UserData)

    const [form] = Form.useForm();
    const handleTabChange = (key) => {
        setActiveTab(key);
    };


    useEffect(() => {
        getData(activeTab === "pendingCitizens" ? true : false)
    }, [activeTab])

    const getData = async (isPending) => {
        setIsLoading(true)
        try {
            const response = await getAllUser(`?IsPending=${isPending}&RoleId=${ROLES.Citizen}`)
            console.log("response", response?.data?.data)
            setData(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }


    const showAddModal = () => {
        setIsAddModalVisible(true);
        form.resetFields();
        setSelectedRecord(null)
    };

    const handleAddModalOk = () => {
        form
            .validateFields()
            .then((values) => {
                console.log('Add:', values);
                setIsAddModalVisible(false);
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    const handleAddModalCancel = () => {
        setIsAddModalVisible(false);
    };

    const showEditModal = (record) => {
        setSelectedRecord(record);
        form.setFieldsValue(record);
        setIsAddModalVisible(true);
    };

    const handleEditModalOk = () => {
        form
            .validateFields()
            .then((values) => {
                console.log('Edit:', values);
                setIsEditModalVisible(false);
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    const handleEditModalCancel = () => {
        setIsEditModalVisible(false);
    };

    const handleDelete = (record) => {
        setShowModal(true)
        setSelectedRecord(record);
    };



    const onNoToggleModal = () => {
        setShowModal(false)
        setSelectedRecord(null);
    }

    const onYesDeleteRecord = async () => {
        setIsLoadingDelete(true)
        try {
            const query = `?loggedInUser=${userData?.userId}&UserId=${selectedRecord.userId}`
            await deleteUser(query)
            toastMessage("Record deleted successfully", true)
            getData(activeTab === "pendingCitizens" ? true : false)
            setIsLoadingDelete(false)
            onNoToggleModal()
        } catch (error) {
            setIsLoadingDelete(false)
        }
    }

    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'fullName',
            key: 'fullName',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Name',
            dataIndex: 'email',
            key: 'email',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Update Date',
            dataIndex: 'updateDate',
            key: 'updateDate',
            render: (updateDate) => (
                <span>{moment(updateDate).local().format('LLL')}</span>
            ),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (createdDate) => (
                <span>{moment(createdDate).local().format('LLL')}</span>
            ),
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
        },
        {
            title: 'Is Deleted',
            dataIndex: 'isDeleted',
            key: 'isDeleted',
            render: (isDeleted) => (isDeleted ? 'Yes' : 'No'),
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div size="middle">
                    <Button className='me-2' onClick={() => showEditModal(record)}>
                        <EditOutlined />
                    </Button>
                    <Button danger onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div>
            {
                isLoadingDelete && <Spinner />
            }
            <GenericModal
                visible={showModal}
                title=" "
                onCancel={onNoToggleModal}
                footer={false}
                width={'25rem'}
                content={<div className='p-4'>
                    <h5 className=' text-center'>Confirmation</h5>
                    <h6 className='text-center mt-4'>Are you sure, you want to remove this record?</h6>
                    <div className='d-flex justify-content-center mt-5'>
                        <Button
                            color='secondary'
                            onClick={onNoToggleModal}
                            style={{ width: '100px' }}
                        >No</Button>
                        <Button
                            type='primary'
                            className='ms-4'
                            style={{ width: '100px' }}
                            onClick={onYesDeleteRecord}
                        >Yes</Button>
                    </div>
                </div>}
            />

            <MyTable
                componentHeader={
                    <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <h4>Citizens</h4>
                            <Button type="primary" onClick={showAddModal} style={{ marginBottom: '16px' }}>
                                Add Citizens
                            </Button>
                        </div>
                        <Tabs activeKey={activeTab} onChange={handleTabChange}>
                            <TabPane tab="All Citizens" key="allCitizens">
                            </TabPane>
                            <TabPane tab="Pending Citizens" key="pendingCitizens">
                            </TabPane>
                        </Tabs>
                    </>
                }
                columns={tableColumns}
                data={data}
                isLoading={isLoading}
            />


            {isAddModalVisible && <Modal
                title={selectedRecord ? "Edit Citizen" : "Add Citizen"}
                visible={isAddModalVisible}
                onOk={null}
                onCancel={handleAddModalCancel}
                width={'50%'}
                footer={false}
            >
                {isAddModalVisible && <AddUser
                    selectedRecord={selectedRecord}
                    setIsAddModalVisible={handleAddModalCancel}
                    role="Citizen" />}
            </Modal>}



            {/* Edit Citizens Modal */}
            <Modal
                title="Edit Citizens"
                visible={isEditModalVisible}
                onOk={handleEditModalOk}
                onCancel={handleEditModalCancel}
            >
                <Form form={form}>
                    <Item label="Name" name="name" rules={[{ required: true, message: 'Please enter a name' }]}>
                        <Input />
                    </Item>
                    <Item label="Age" name="age" rules={[{ required: true, message: 'Please enter an age' }]}>
                        <Input type="number" />
                    </Item>
                    <Item label="Address" name="address" rules={[{ required: true, message: 'Please enter an address' }]}>
                        <Input />
                    </Item>
                    <Item label="Tags" name="tags">
                        <Input />
                    </Item>
                </Form>
            </Modal>
        </div>
    );
};
