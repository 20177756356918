import { Fragment } from "react";
import "./stylesheet/style.css";
import MainRouter from "./router/appRouter";
import { ConfigProvider } from "antd";
import { store } from "./redux";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const primaryColor = "#F4A51F";
const secondaryColor = "#181E3C";

// Create a custom theme object
const theme = {
  "primary-color": primaryColor,
  "info-color": primaryColor,
  "processing-color": primaryColor,
  "border-radius-base": "4px",
  "secondary-color": secondaryColor,
};

function App() {
  return (
    <Provider store={store}>
      <Fragment>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: primaryColor,
            },
          }}
        >
          <MainRouter />
        </ConfigProvider>
      </Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Provider>
  );
}

export default App;
