// firebase.js
import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";
import { toast } from "react-toastify";
import { updateDeviceAccount } from "./ApiManager";

const firebaseConfig = {
  apiKey: "AIzaSyAskH-skTlSzkwHXBYYe7BGyRJMRNClsc8",
  authDomain: "wakalatnaama-f6493.firebaseapp.com",
  projectId: "wakalatnaama-f6493",
  storageBucket: "wakalatnaama-f6493.appspot.com",
  messagingSenderId: "528140156856",
  appId: "1:528140156856:web:1234567890abcdef",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      // Get the FCM token

      const isLoggedIn = localStorage.getItem("loggedIn");
      if (isLoggedIn) {
        const token = await getToken(messaging);
        console.log("FCM Token:", token);
        if (token) {
          const response = await updateDeviceAccount({ token: token });
          console.log("updateDeviceAccount", response);
        }
      } else {
        console.log("token not found!");
      }
    } else {
      console.log("Notification permission denied.");
    }

    // Handle foreground notifications
    onMessage(messaging, (payload) => {
      toast.info(payload?.notification?.body);
    });
  } catch (error) {
    console.error("Error setting up notifications:", error);
  }
};
export { messaging, setupNotifications };
