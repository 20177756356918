import React, { useState, useEffect } from 'react';
import { Steps, Button, Select, Row, Col, Tooltip, Form, Input, Modal, DatePicker } from 'antd';
import { Card, Typography, } from 'antd';
import moment from 'moment';
import { EyeOutlined, DeleteOutlined, EditOutlined, PaperClipOutlined } from '@ant-design/icons';
import MyTable from '../../../components/MyTable';
import { createCaseDateByAdmin, deleteCaseById, deleteCaseDateById, getApprovedCaseDetails, getCaseDateByAdmin, getCaseDateListByAdmin, getCaseDetails, getCaseStatusesByCaseId, updateCaseDateByAdmin } from '../../../Utils/ApiManager';
import { useDispatch, useSelector } from 'react-redux';
import { GenericModal } from '../../../components/GenericModal';
import { resetCaseWithDateDetails } from '../../../redux/actions';
import { useNavigate } from 'react-router-dom';
import { EditCaseDetails } from './../Cases/EditCaseDetails/EditCaseDetails';
import { Spinner } from '../../../components/Spinner';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import download from 'downloadjs';

const { Text, Title } = Typography;
const { Item } = Form;
const { Option } = Select;

export const CasesWithDatesDetails = () => {
    const [caseDateId, setCaseDateId] = useState('')
    const [isLoading, setIsLoading] = useState('')
    const [data, setData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showModalCase, setShowModalCase] = useState(false)
    const [caseDetails, setCaseDetails] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [primaryLawyerDetails, setPrimaryLawyerDetails] = useState(null);

    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const [editCaseModel, setEditCaseModel] = useState(false);
    const [caseStatus, setCaseStatus] = useState(null);

    const caseWithDateDetails = useSelector((state) => state.CaseWithDateDetails)
    const userDataRedux = useSelector(state => state.UserData)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [form] = Form.useForm();

    const tableColumns = [
        {
            title: 'Hearing Date ID',
            dataIndex: 'hearingDateId',
            key: 'hearingDateId',
        },
        {
            title: 'Case ID',
            dataIndex: 'caseId',
            key: 'caseId',
        },
        {
            title: 'Citizen ID',
            dataIndex: 'citizenId',
            key: 'citizenId',
        },
        {
            title: 'Lawyer ID',
            dataIndex: 'lawyerId',
            key: 'lawyerId',
        },
        {
            title: 'Redundant Lawyer ID',
            dataIndex: 'redundantLawyerId',
            key: 'redundantLawyerId',
        },
        // {
        //     title: 'Case Number',
        //     dataIndex: 'caseNumber',
        //     key: 'caseNumber',
        // },
        // {
        //     title: 'Party ID',
        //     dataIndex: 'partyId',
        //     key: 'partyId',
        // },
        // {
        //     title: 'Category ID',
        //     dataIndex: 'categoryId',
        //     key: 'categoryId',
        // },
        {
            title: 'Date Description',
            dataIndex: 'dateDescription',
            key: 'dateDescription',
        },
        {
            title: 'Date Title',
            dataIndex: 'dateTitle',
            key: 'dateTitle',
        },
        {
            title: 'Hearing Date',
            dataIndex: 'hearingDate',
            key: 'hearingDate',
            render: (hearingDate) => moment(hearingDate).local().format('lll'),
        },
        // {
        //     title: 'Hearing Documents',
        //     dataIndex: 'hearingDocuments',
        //     key: 'hearingDocuments',
        // },
        {
            title: 'Case Status ID',
            dataIndex: 'caseStatusId',
            key: 'caseStatusId',
        },
        // {
        //     title: 'Case Title',
        //     dataIndex: 'caseTitle',
        //     key: 'caseTitle',
        // },
        // {
        //     title: 'Document Name',
        //     dataIndex: 'docName',
        //     key: 'docName',
        // },
        // {
        //     title: 'Case Detail ID',
        //     dataIndex: 'caseDetailId',
        //     key: 'caseDetailId',
        // },
        // {
        //     title: 'Case Placed For',
        //     dataIndex: 'casePlacedFor',
        //     key: 'casePlacedFor',
        // },
        {
            title: 'Date Files',
            dataIndex: 'dateFiles',
            key: 'dateFiles',
            render: (dateFiles) => (dateFiles && dateFiles.length > 0 ? 'Yes' : 'No'),
        },
        {
            title: 'Case Date Status',
            dataIndex: 'caseDateStatus',
            key: 'caseDateStatus',
        },
        // {
        //     title: 'Created Date',
        //     dataIndex: 'createdDate',
        //     key: 'createdDate',
        //     render: (createdDate) => createdDate ? moment(createdDate).local().format('lll') : '',
        // },
        {
            title: 'Actions',
            key: 'actions',
            width: '15%',
            render: (_, record) => (
                <div>
                    <Tooltip title="View Date Details">
                        <Button
                            type="primary"
                            icon={<EyeOutlined />}
                            style={{ marginLeft: '8px' }}
                            onClick={() => handleView(record)}
                        />

                        <Button
                            type="primary"

                            icon={<EditOutlined />}
                            style={{ marginLeft: '8px' }}
                            onClick={() => showEditModal(record)}
                        />

                        <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            style={{ marginLeft: '8px' }}
                            onClick={() => handleDelete(record)}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const handleView = (record) => {
        setCaseDateId(record?.hearingDateId)
        navigate("/settings/casesWithDates/DateDetails")


    }

    useEffect(() => {
        if (caseWithDateDetails?.caseId && userDataRedux?.userId) {
            getCaseDateListData()
            getCaseDetailData()
            getCaseStatusData()
        }

        // return () => {
        //     dispatch(resetCaseWithDateDetails())
        // }
    }, [caseWithDateDetails])

    const getCaseDateListData = async () => {
        setIsLoading(true)
        try {
            const response = await getCaseDateListByAdmin(`userId=${userDataRedux?.userId}&caseId=${caseWithDateDetails?.caseId}`)
            const listDate = response?.data?.data
            setData(listDate.map((date) => { return { ...date, hearingDate: moment(date.hearingDate).local().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") } }))
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }

    const getCaseDetailData = async () => {
        setIsLoading(true)
        try {
            const response = await getApprovedCaseDetails(`?CaseId=${caseWithDateDetails?.caseId}`)
            setCaseDetails(response?.data?.data?.caseDetails)
            setUserDetails(response?.data?.data?.citizenDetail)
            setPrimaryLawyerDetails(response?.data?.data?.lawyerDetail)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    const getCaseStatusData = async () => {
        setIsLoading(true)
        try {
            const response = await getCaseStatusesByCaseId(`CaseId=${caseWithDateDetails?.caseId}`)
            console.log("response", response?.data?.data)
            setCaseStatus(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }

    const deleteCaseDate = async () => {
        setIsLoading(true)
        try {
            const response = await deleteCaseDateById(`userId=${userDataRedux?.userId}&caseId=${caseWithDateDetails?.caseId}&dateId=${caseDateId}`)
            console.log("response", response?.data?.data)
            setIsLoading(false)
            onNoToggleModal()
            getCaseDateListData()
            toast.success("Record delete successfully")
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }

    const handleDelete = (record) => {
        setShowModal(true)
        setCaseDateId(record?.hearingDateId)
    };

    const onNoToggleModal = () => {
        setShowModal(false)
        setCaseDateId(null);
    }


    const handleDeleteCase = () => {
        setShowModalCase(true)
    };
    const onNoToggleModalCase = () => {
        setShowModalCase(false)
    }
    const onYesDeleteCaseRecord = async () => {
        setIsLoading(true)
        try {
            await deleteCaseById(`userId=${userDataRedux?.userId}&caseId=${caseWithDateDetails.caseId}`)
            setIsLoading(true)
            onNoToggleModalCase()
            navigate("/settings/casesWithDates")
            toast.success("Record delete successfully")
        } catch (error) {
            setIsLoading(false)
        }
    }



    const showAddModal = () => {
        setIsAddModalVisible(true);
        form.resetFields();
    };
    const handleAddModalOk = () => {
        form
            .validateFields()
            .then(async (values) => {
                setIsAddModalVisible(false);
                const payload = {
                    "caseDateId": 0,
                    "caseId": caseWithDateDetails?.caseId,
                    "dateDescription": values.dateDescription,
                    "dateTitle": values.dateTitle,
                    "hearingDate": values.hearingDate,
                    "caseStatusId": values.caseStatusId
                }
                await createCaseDateByAdmin(payload)
                getCaseDateListData()
                toast.success("Record added successfully")
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };
    const handleAddModalCancel = () => {
        setIsAddModalVisible(false);
    };
    const handleEditModalOk = () => {
        form
            .validateFields()
            .then(async (values) => {
                const payload = {
                    "caseDateId": values.caseDateId,
                    "caseId": caseWithDateDetails?.caseId,
                    "dateDescription": values.dateDescription,
                    "dateTitle": values.dateTitle,
                    "hearingDate": values.hearingDate,
                    "caseStatusId": values.caseStatusId
                }
                await updateCaseDateByAdmin(payload)
                setIsEditModalVisible(false);
                getCaseDateListData()
                toast.success("Record updated successfully")
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };
    console.log("selectedRecord", selectedRecord)
    const showEditModal = (record) => {
        setSelectedRecord(record);
        // form.setFieldsValue(record);
        setIsEditModalVisible(true);
    };

    const handleEditModalCancel = () => {
        setIsEditModalVisible(false);
    };


    const handleFileDownload = (fileUrl) => {
        // Extract the filename from the URL (last part of the URL)
        const fileName = fileUrl.split('/').pop();

        // Fetch the file and trigger download
        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => download(blob, fileName))
            .catch(err => console.error('Error downloading the file:', err));
    };


    const hearingDate = selectedRecord?.hearingDate ? moment(selectedRecord.hearingDate) : null;

    return (
        <div>
            {isLoading && <Spinner />}


            {/* delete case date model  */}
            <GenericModal
                visible={showModal}
                title=" "
                onCancel={onNoToggleModal}
                footer={false}
                width={'25rem'}
                content={<div className='p-4'>
                    <h5 className=' text-center'>Confirmation</h5>
                    <h6 className='text-center mt-4'>Are you sure, you want to remove this record?</h6>
                    <div className='d-flex justify-content-center mt-5'>
                        <Button
                            color='secondary'
                            onClick={onNoToggleModal}
                            style={{ width: '100px' }}
                        >No</Button>
                        <Button
                            type='primary'
                            className='ms-4'
                            style={{ width: '100px' }}
                            onClick={deleteCaseDate}
                        >Yes</Button>
                    </div>
                </div>}
            />


            {/* delete case model  */}
            <GenericModal
                visible={showModalCase}
                title=" "
                onCancel={onNoToggleModalCase}
                footer={false}
                width={'25rem'}
                content={<div className='p-4'>
                    <h5 className=' text-center'>Confirmation</h5>
                    <h6 className='text-center mt-4'>Are you sure, you want to remove this record?</h6>
                    <div className='d-flex justify-content-center mt-5'>
                        <Button
                            color='secondary'
                            onClick={onNoToggleModalCase}
                            style={{ width: '100px' }}
                        >No</Button>
                        <Button
                            type='primary'
                            className='ms-4'
                            style={{ width: '100px' }}
                            onClick={onYesDeleteCaseRecord}
                        >Yes</Button>
                    </div>
                </div>}
            />


            <Modal
                width={1100}
                title=""
                visible={editCaseModel}
                onOk={() => null}
                onCancel={() => setEditCaseModel(false)}
                footer={false}
            >
                <EditCaseDetails newCaseModelTitle={"Edit Case Details"} />
            </Modal>



            <div className="d-flex mb-2" style={{ justifyContent: 'space-between', alignContent: 'center' }}>
                <Title level={4}>Case Details</Title>
                <div className='d-flex'>
                    <Button
                        type="primary"
                        style={{ marginLeft: '8px' }}
                        onClick={() => {
                            navigate(`/settings/casesWithDates/details?caseId=${caseDetails?.caseId}`)
                            setEditCaseModel(true)
                        }}
                    >
                        Edit Case
                    </Button>
                    <Button
                        type="primary"
                        danger
                        icon={<DeleteOutlined />}
                        style={{ marginLeft: '8px' }}
                        onClick={() => handleDeleteCase()}
                    >Delete Case </Button>
                </div>
            </div>
            <Card style={{
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                borderRadius: '8px',
                padding: '10px',
            }}>
                <Row gutter={[16, 16]} justify="space-between">
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Title level={5}> Details</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>ID:</Text>
                                    <Text strong> {caseDetails?.caseId}</Text>
                                </div>
                                <div>
                                    <Text>Title:</Text>
                                    <Text strong> {caseDetails?.caseTitle}</Text>
                                </div>
                                <div>
                                    <Text>Number:</Text>
                                    <Text strong> {caseDetails?.caseNumber}</Text>
                                </div>
                                <div>
                                    <Text>Status:</Text>
                                    <Text strong> {caseDetails?.caseStatus}</Text>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>Placing:</Text>
                                    <Text strong> {caseDetails?.casePlacing}</Text>
                                </div>
                                <div>
                                    <Text>Created Date:</Text>
                                    <Text strong> {new Date(caseDetails?.createdDate).toLocaleString()}</Text>
                                </div>
                                <div>
                                    <Text>Legal Status:</Text>
                                    <Text strong> {caseDetails?.legalStatus}</Text>
                                </div>
                            </Col>
                        </Row>

                        <div className='mt-3'>
                            Files:
                            {caseDetails?.caseFiles && caseDetails.caseFiles.length > 0 ? caseDetails.caseFiles.map((file, key) => {
                                return (
                                    <div key={key + file.docId}>
                                        <p className='mb-0'>
                                            <Button
                                                type="link"
                                                icon={<PaperClipOutlined />}
                                                href={file.docUrl}
                                                onClick={() => handleFileDownload(file.docUrl)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {file.docName}
                                            </Button>
                                        </p>
                                    </div>
                                );
                            }) :
                                " No files attached..."
                            }
                        </div>


                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>

                        <Title level={5}>User Info</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <div>
                                    <Text>CNIC:</Text>
                                    <Text strong> {userDetails?.cnicNo}</Text>
                                </div>
                                <div>
                                    <Text>Contact Number:</Text>
                                    <Text strong> {userDetails?.contactNumber}</Text>
                                </div>
                                <div>
                                    <Text>Current Address:</Text>
                                    <Text strong> {userDetails?.currAddress}</Text>
                                </div>
                                <div>
                                    <Text>Email:</Text>
                                    <Text strong> {userDetails?.email}</Text>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>

                                <div>
                                    <Text>Full Name:</Text>
                                    <Text strong> {userDetails?.fullName}</Text>
                                </div>
                                <div>
                                    <Text>Permanent Address:</Text>
                                    <Text strong> {userDetails?.permAddress}</Text>
                                </div>
                                <div>
                                    <Text>User ID:</Text>
                                    <Text strong> {userDetails?.userId}</Text>
                                </div>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>

            <br />
            <MyTable
                componentHeader={
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <h5>Case Dates</h5>
                        <Button type="primary" onClick={() => { showAddModal() }} style={{ marginBottom: '16px' }}>
                            Add
                        </Button>
                    </div>
                }
                columns={tableColumns}
                data={data}
                isLoading={isLoading}
            />



            <Modal
                title="Add Case Date"
                visible={isAddModalVisible}
                onOk={handleAddModalOk}
                onCancel={handleAddModalCancel}
            >
                <Form form={form} layout="vertical">
                    <Item label="Date Description" name="dateDescription" rules={[{ required: true, message: 'Please enter a date description' }]}>
                        <Input />
                    </Item>
                    <Item label="Date Title" name="dateTitle" rules={[{ required: true, message: 'Please enter a date title' }]}>
                        <Input />
                    </Item>
                    <Item label="Hearing Date" name="hearingDate" rules={[{ required: true, message: 'Please select a hearing date' }]}>
                        <DatePicker showTime />
                    </Item>
                    <Item label="Case Status ID" name="caseStatusId" rules={[{ required: true, message: 'Please select a case status ID' }]}>
                        <Select>
                            {
                                caseStatus?.map((status) => {
                                    return <Option key={status?.id} value={status?.id}>{status?.status}</Option>
                                })
                            }
                        </Select>
                    </Item>

                </Form>
            </Modal>

            <Modal
                title="Edit "
                visible={isEditModalVisible}
                onOk={handleEditModalOk}
                onCancel={handleEditModalCancel}
            >
                <Form form={form} layout="vertical" initialValues={selectedRecord}>
                    <Item label="Date Description" name="dateDescription" rules={[{ required: true, message: 'Please enter a date description' }]}>
                        <Input />
                    </Item>
                    <Item label="Date Title" name="dateTitle" rules={[{ required: true, message: 'Please enter a date title' }]}>
                        <Input />
                    </Item>
                    {/* <Item label="Hearing Date" name="hearingDate" rules={[{ required: true, message: 'Please select a hearing date' }]}>
                        <DatePicker
                            showTime
                            value={hearingDate}
                        />
                    </Item> */}

                    <Item label="Case Status ID" name="caseStatusId" rules={[{ required: true, message: 'Please select a case status ID' }]}>
                        <Select>
                            {
                                caseStatus?.map((status) => {
                                    return <Option key={status?.id} value={status?.id}>{status?.status}</Option>
                                })
                            }
                        </Select>
                    </Item>
                </Form>
            </Modal>
        </div >
    )
}
