import React, { useEffect, useState } from 'react'
import { MessagesList } from './MessagesList'
import { Button, Card, Col, Input, Row, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { getAllUser, getChatHistory } from '../../Utils/ApiManager';
import { ROLES } from '../../Utils/Constants';
import { Spinner } from '../../components/Spinner';
const { Option } = Select;

export const PrivateChat = () => {
    const [fistUser, setFirstUser] = useState('');
    const [secondUser, setSecondUser] = useState('');
    const [userList, setUserList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [myChats, setMyChats] = useState([])

    useEffect(() => {
        getUserData()
    }, [])

    const getUserData = async () => {
        setIsLoading(true)
        try {
            const response = await getAllUser(`?IsPending=${false}&RoleId=${ROLES.ALL}`)
            console.log("response", response?.data?.data)
            setUserList(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }


    const selectFirstUser = userId => {
        if (userId) {
            const user = userList?.find(user => user.userId === userId)
            if (user) {
                setFirstUser(user.userId);
            }
        } else {
            setFirstUser(null);
        }
    };

    const selectSecondUser = userId => {
        if (userId) {
            const user = userList?.find(user => user.userId === userId)
            if (user) {
                setSecondUser(user.userId);
            }
        } else {
            setSecondUser(null);
        }
    };

    const searchForPrivateChat = async () => {
        setIsLoading(true)
        try {
            const response = await getChatHistory(`FromId=${fistUser}&ToId=${secondUser}`)
            console.log("response", response?.data?.data)
            setMyChats(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }


    return (
        <div>
            {
                isLoading
                && <Spinner />
            }
            <h6 className='mb-4'>Search for private chat</h6>
            <Row gutter={[16, 16]} style={{ width: '100%', marginTop: '20px' }}>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>

                    <div style={{ width: '100%' }}>
                        <label>Select first user</label>
                        <br />
                        <Select
                            onChange={value => { selectFirstUser(value) }}
                            style={{ width: '100%' }}
                            defaultValue={null}
                            value={fistUser}

                        >
                            <Option value={null}>Please Select First User</Option>
                            {
                                userList?.map((user, key) => {
                                    return (
                                        <Option key={key} value={user.userId}>{user.fullName}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>

                    <div style={{ width: '100%' }}>
                        <label>Select second user</label>
                        <br />
                        <Select
                            className='ms-2'
                            onChange={value => { selectSecondUser(value) }}
                            style={{ width: '100%' }}
                            defaultValue={null}
                            value={secondUser}

                        >
                            <Option value={null}>Please Select Second User</Option>
                            {
                                userList?.map((user, key) => {
                                    return (
                                        <Option key={key} value={user.userId}>{user.fullName}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                    <Button style={{ width: '200px' }} className='ms-3 me-1' type='primary'
                        onClick={searchForPrivateChat}
                        disabled={!fistUser || !secondUser}>Search</Button>
                    <Button onClick={() => {
                        setFirstUser("")
                        setSecondUser("")
                        setMyChats([])
                    }}>Clear Search</Button>
                </Col>
            </Row>
            <h6 className=' mt-4' style={{}}>User to User Chat</h6>
            <Card >
                <MessagesList privateMessage={myChats} />
            </Card>
        </div>
    )
}
