export const ROLES = {
  ALL: 0,
  Admin: 1,
  Zonal_Manager: 2,
  Citizen: 3,
  Lawyer: 4,
  Employee: 5,
};

export const EDocumentType = [
  { name: "None", value: 0 },
  { name: "NicFront", value: 1 },
  { name: "NicBack", value: 2 },
  { name: "ProfilePic", value: 3 },
  { name: "NICOPFront", value: 4 },
  { name: "NICOPFback", value: 5 },
  { name: "EducationPic", value: 6 },
  { name: "CertificationPic", value: 7 },
  { name: "PassportPicFront", value: 8 },
  { name: "PassportPicBack", value: 9 },
  { name: "BarCouncilCardScanFront", value: 10 },
  { name: "BarCouncilCardScanBack", value: 11 },
  { name: "CitizenDownloadable", value: 12 },
  { name: "LawyerDownloadable", value: 13 },
  { name: "CaseDocument", value: 14 },
  { name: "CaseDateDocument", value: 15 },
  { name: "PaymentReceipt", value: 16 },
  { name: "InMessageDoc", value: 17 },
];
