import React, { useEffect, useState } from 'react'
import MyTable from '../../components/MyTable';
import { getCases, getCasesForAdminApproval, } from '../../Utils/ApiManager';
import { Button, Modal, Form, Input, Tabs, DatePicker, Checkbox, Row, Col } from 'antd';
import moment from 'moment';
import { Spinner } from '../../components/Spinner';
import { useNavigate } from 'react-router-dom';

const { Item } = Form;

const { TabPane } = Tabs;

export const NewCasesTable = () => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)
    const [activeTab, setActiveTab] = useState('allNewCases');
    const [searchQuery, setSearchQuery] = useState('');

    const navigate = useNavigate()
    const [form] = Form.useForm();
    const handleTabChange = (key) => {
        setActiveTab(key);
    };


    useEffect(() => {
        getData()
    }, [activeTab])

    const getData = async () => {
        setIsLoading(true)
        try {
            const response = await getCasesForAdminApproval()
            console.log("response", response?.data?.data)
            setData(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }


    const showAddModal = () => {
        setIsAddModalVisible(true);
        form.resetFields();
    };

    const handleAddModalOk = () => {
        form
            .validateFields()
            .then((values) => {
                console.log('Add:', values);
                setIsAddModalVisible(false);
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    const handleAddModalCancel = () => {
        setIsAddModalVisible(false);
    };

    const handleViewDetails = (record) => {
        navigate(`/newcases/details?caseId=${record.caseId}`)
    };




    // Function to handle search query change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filtering function based on search query
    const filteredData = data.filter(item =>
        item.caseTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.userFullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.caseId.toString().includes(searchQuery) ||
        item.categoryName.toLowerCase().includes(searchQuery.toLowerCase())
    );



    const tableColumns = [
        {
            title: 'Title',
            dataIndex: 'caseTitle',
            key: 'caseTitle',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'User Name',
            dataIndex: 'userFullName',
            key: 'userFullName',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Case Id',
            dataIndex: 'caseId',
            key: 'caseId',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Category Name',
            dataIndex: 'categoryName',
            key: 'categoryName',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Update Date',
            dataIndex: 'updateDate',
            key: 'updateDate',
            render: (updateDate) => (
                <span>{moment(updateDate).local().format('LLL')}</span>
            ), // Format date using Moment.js
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (createdDate) => (
                <span>{moment(createdDate).local().format('LLL')}</span>
            ), // Format date using Moment.js
        },
        {
            title: 'Is Deleted',
            dataIndex: 'isDeleted',
            key: 'isDeleted',
            render: (isDeleted) => (isDeleted ? 'Yes' : 'No'),
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div size="middle">
                    <Button
                        type='primary'
                        className='me-2'
                        onClick={() => handleViewDetails(record)}>
                        View Details
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div>
            {
                isLoadingDelete && <Spinner />
            }
            <h4 className='mb-4'>New Cases</h4>

            <MyTable

                componentHeader={
                    <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{ width: '40%', marginBottom: '16px' }}>
                                <Input placeholder="Search by Title, User Name, case Id and Category Name" value={searchQuery} onChange={handleSearchChange} />
                            </div>
                            <Button type="primary" onClick={showAddModal} style={{ marginBottom: '16px' }}>
                                Add New Cases
                            </Button>
                        </div>
                        <Tabs activeKey={activeTab} onChange={handleTabChange}>
                            <TabPane tab="Pending Cases" key="allNewCases">
                            </TabPane>
                        </Tabs>
                    </>
                }
                columns={tableColumns}
                data={filteredData}
                isLoading={isLoading}
            />


            <Modal
                title="Add Case"
                visible={isAddModalVisible}
                onOk={handleAddModalOk}
                onCancel={handleAddModalCancel}
            >
                <Form form={form} layout="vertical">
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Case ID" name="caseId" rules={[{ required: true, message: 'Please enter a Case ID' }]}>
                                <Input type="number" />
                            </Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Citizen ID" name="citizenId" rules={[{ required: true, message: 'Please enter a Citizen ID' }]}>
                                <Input type="number" />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Lawyer ID" name="lawyerId" rules={[{ required: true, message: 'Please enter a Lawyer ID' }]}>
                                <Input type="number" />
                            </Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Redundant Lawyer ID" name="redundantLawyerId">
                                <Input type="number" />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Case Number" name="caseNumber" rules={[{ required: true, message: 'Please enter a Case Number' }]}>
                                <Input />
                            </Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Party ID" name="partyId">
                                <Input type="number" />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Category ID" name="categoryId">
                                <Input type="number" />
                            </Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Case Nature ID" name="caseNatureId">
                                <Input type="number" />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Case Description" name="caseDescription">
                                <Input.TextArea />
                            </Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Case Jurisdiction ID" name="caseJurisdictionId">
                                <Input type="number" />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Court ID" name="courtId">
                                <Input type="number" />
                            </Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Case Placing ID" name="casePlacingId">
                                <Input type="number" />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Created Date" name="createdDate">
                                <DatePicker showTime />
                            </Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Case Title" name="caseTitle">
                                <Input />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="User Full Name" name="userFullName">
                                <Input />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Category Name" name="categoryName">
                                <Input />
                            </Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Case Status ID" name="caseStatusId">
                                <Input type="number" />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Assign Employee ID" name="assignEmployeeId">
                                <Input type="number" />
                            </Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Case Status" name="caseStatus">
                                <Input />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Created User Name" name="createdUserName">
                                <Input />
                            </Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Item label="Case Placed For" name="casePlacedFor">
                                <Input />
                            </Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};
