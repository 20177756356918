import React, { useEffect, useState } from 'react'
import MyTable from '../../components/MyTable';
import { deleteAccount, deleteUser, getAccount, getAllUser, getUsers } from '../../Utils/ApiManager';
import { Tag, Button, Modal, Form, Input, Tabs, Row, Col, Select } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { GenericModal } from '../../components/GenericModal';
import { Spinner } from '../../components/Spinner';
import { AddUser } from './AddUser';
import { EditUser } from './EditUser';
import { ROLES } from '../../Utils/Constants';
import moment from 'moment';
import { mapRoleIdToRoleName, toastMessage } from '../../Utils/Utils';
import { useSelector } from 'react-redux';

const { Item } = Form;
const { Option } = Select
const { TabPane } = Tabs;

export const Users = () => {
    const [userData, setUserData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('allUsers');
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showModal, setShowModal] = useState(false)
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)
    const userDataRedux = useSelector(state => state.UserData)

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const [form] = Form.useForm();


    useEffect(() => {
        getUserData(activeTab === "pendingUsers" ? true : false)
    }, [activeTab])

    const getUserData = async (isPending) => {
        setIsLoading(true)
        try {
            const response = await getAllUser(`?IsPending=${isPending}&RoleId=${ROLES.ALL}`)
            console.log("response", response?.data?.data)
            setUserData(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }


    const showAddModal = () => {
        setIsAddModalVisible(true);
        form.resetFields(); // Reset form fields when opening the modal
    };



    const handleAddModalCancel = () => {
        setIsAddModalVisible(false);
    };

    const showEditModal = (record) => {
        setSelectedRecord(record);
        form.setFieldsValue(record); // Set initial values for Edit User form
        setIsAddModalVisible(true);
    };

    const handleEditModalOk = () => {
        form
            .validateFields()
            .then((values) => {
                // Handle the form submission logic for editing here
                console.log('Edit User:', values);
                setIsEditModalVisible(false);
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    const handleEditModalCancel = () => {
        setIsEditModalVisible(false);
    };

    const handleDelete = (record) => {
        setShowModal(true)
        setSelectedRecord(record);
    };

    const onNoToggleModal = () => {
        setShowModal(false)
        setSelectedRecord(null);
    }

    const onYesDeleteRecord = async () => {
        setIsLoadingDelete(true)
        try {
            const query = `?loggedInUser=${userDataRedux?.userId}&UserId=${selectedRecord.userId}`
            await deleteUser(query)
            toastMessage("Record deleted successfully", true)
            getUserData(activeTab === "pendingUsers" ? true : false)
            setIsLoadingDelete(false)
            onNoToggleModal()
        } catch (error) {
            setIsLoadingDelete(false)
        }
    }



    const tableColumns = [
        {
            title: 'User Name',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Email Confirmed',
            dataIndex: 'emailConfirmed',
            key: 'emailConfirmed',
            render: (text) => <>{text ? "Yes" : "No"}</>
        },
        {
            title: 'Role Id',
            dataIndex: 'roleId',
            key: 'roleId',
            render: (roleId) => <>{mapRoleIdToRoleName(roleId)}</>
        },
        {
            title: 'ID',
            dataIndex: 'userId',
            key: 'userId',
        },

        {
            title: 'Is Deleted',
            dataIndex: 'isDeleted',
            key: 'isDeleted',
            render: (text) => <>{text ? "Yes" : "No"}</>
        }, {
            title: 'Is Verified',
            dataIndex: 'isVerified',
            key: 'isVerified',
            render: (text) => <>{text ? "Yes" : "No"}</>
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Phone Number Confirmed',
            dataIndex: 'phoneNumberConfirmed',
            key: 'phoneNumberConfirmed',
            render: (text) => <>{text ? "Yes" : "No"}</>
        },
        {
            title: 'Two Factor Enabled',
            dataIndex: 'twoFactorEnabled',
            key: 'twoFactorEnabled',
            render: (text) => <>{text ? "Yes" : "No"}</>
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (createdDate) => <>{moment(createdDate).local().format('lll')}</>
        },
        {
            title: 'Update Date',
            dataIndex: 'updateDate',
            key: 'updateDate',
            render: (updateDate) => <>{moment(updateDate).local().format('lll')}</>
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div size="middle">
                    <Button className='me-2' onClick={() => showEditModal(record)}>
                        <EditOutlined />
                    </Button>
                    <Button danger onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </div>
            ),
        },
    ];

    const hanleCloseModel = (check) => {
        setIsAddModalVisible(check)
        getUserData(activeTab === "pendingUsers" ? true : false)
    }

    return (
        <div>
            {
                isLoadingDelete && <Spinner />
            }
            <GenericModal
                visible={showModal}
                title=" "
                onCancel={onNoToggleModal}
                footer={false}
                width={'25rem'}
                content={<div className='p-4'>
                    <h5 className=' text-center'>Confirmation</h5>
                    <h6 className='text-center mt-4'>Are you sure, you want to remove this record?</h6>
                    <div className='d-flex justify-content-center mt-5'>
                        <Button
                            color='secondary'
                            onClick={onNoToggleModal}
                            style={{ width: '100px' }}
                        >No</Button>
                        <Button
                            type='primary'
                            className='ms-4'
                            style={{ width: '100px' }}
                            onClick={onYesDeleteRecord}
                        >Yes</Button>
                    </div>
                </div>}
            />

            <MyTable
                componentHeader={
                    <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <h4>Users</h4>
                            <Button type="primary" onClick={showAddModal} style={{ marginBottom: '16px' }}>
                                Add User
                            </Button>
                        </div>

                        <Tabs activeKey={activeTab} onChange={handleTabChange}>
                            <TabPane tab="All Users" key="allUsers">
                            </TabPane>
                            <TabPane tab="Pending Users" key="pendingUsers">
                            </TabPane>
                        </Tabs>
                    </>
                }
                columns={tableColumns}
                data={userData}
                isLoading={isLoading}
            />

            {isAddModalVisible && <Modal
                title={selectedRecord ? "Edit User" : "Add User"}
                visible={isAddModalVisible}
                onOk={null}
                onCancel={handleAddModalCancel}
                width={'50%'}
                footer={false}
            >
                {isAddModalVisible && <AddUser
                    selectedRecord={selectedRecord}
                    setIsAddModalVisible={hanleCloseModel} />}
            </Modal>}


            <Modal
                title="Edit User"
                visible={isEditModalVisible}
                onOk={null}
                onCancel={handleEditModalCancel}
                footer={false}

            >
                {isEditModalVisible && <EditUser />}
            </Modal>
        </div>
    );
};
