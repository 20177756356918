import React, { useEffect, useState } from 'react'
import { Tag, Button, Modal, Form, Input, Popconfirm, Tooltip } from 'antd';
import { deleteCase, deleteCaseById, getAllCasesByAdmin, getCases, getCategories } from '../../../Utils/ApiManager';
import MyTable from '../../../components/MyTable';
import moment from 'moment';
import { GenericModal } from '../../../components/GenericModal';
import { Spinner } from '../../../components/Spinner';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { caseWithDateDetails } from '../../../redux/actions';
import { toast } from 'react-toastify';

const { Item } = Form;


export const CasesWithDates = () => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const userDataRedux = useSelector(state => state.UserData)


    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        setIsLoading(true)
        try {
            const response = await getAllCasesByAdmin()
            console.log("response", response?.data?.data)
            setData(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }


    const showAddModal = () => {
        setIsAddModalVisible(true);
        form.resetFields();
    };

    const handleAddModalOk = () => {
        form
            .validateFields()
            .then((values) => {
                setIsAddModalVisible(false);
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    const handleAddModalCancel = () => {
        setIsAddModalVisible(false);
    };

    const showEditModal = (record) => {
        setSelectedRecord(record);
        form.setFieldsValue(record);
        setIsEditModalVisible(true);
    };

    const handleEditModalOk = () => {
        form
            .validateFields()
            .then((values) => {
                setIsEditModalVisible(false);
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    const handleEditModalCancel = () => {
        setIsEditModalVisible(false);
    };

    const handleDelete = (record) => {
        setShowModal(true)
        setSelectedRecord(record);
    };

    const onNoToggleModal = () => {
        setShowModal(false)
        setSelectedRecord(null);
    }

    const onYesDeleteRecord = async () => {
        setIsLoadingDelete(true)
        try {
            await deleteCaseById(`userId=${userDataRedux?.userId}&caseId=${selectedRecord.caseId}`)
            getCases()
            setIsLoadingDelete(false)
            onNoToggleModal()
            toast.success("Record delete successfully")
        } catch (error) {
            setIsLoadingDelete(false)
        }
    }

    const tableColumns = [
        {
            title: 'Case ID',
            dataIndex: 'caseId',
            key: 'caseId',
        },
        {
            title: 'Case Description',
            dataIndex: 'caseDescription',
            key: 'caseDescription',
        },
        {
            title: 'Case Title',
            dataIndex: 'caseTitle',
            key: 'caseTitle',
        },
        {
            title: 'Citizen ID',
            dataIndex: 'citizenId',
            key: 'citizenId',
        },
        {
            title: 'Citizen Name',
            dataIndex: 'citizenName',
            key: 'citizenName',
        },
        {
            title: 'Lawyer ID',
            dataIndex: 'lawyerId',
            key: 'lawyerId',
        },
        {
            title: 'Lawyer Name',
            dataIndex: 'lawyerName',
            key: 'lawyerName',
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (createdDate) => moment(createdDate).local().format('lll'),
        },
        {
            title: 'Status ID',
            dataIndex: 'statusId',
            key: 'statusId',
        },
        {
            title: 'Case Status',
            dataIndex: 'eCaseStatus',
            key: 'eCaseStatus',
        },
        {
            title: 'Legal Status ID',
            dataIndex: 'leagalStatusId',
            key: 'leagalStatusId',
        },
        {
            title: 'Legal Status',
            dataIndex: 'leagalStatus',
            key: 'leagalStatus',
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '15%',
            render: (_, record) => (
                <div>
                    <Tooltip title="View Case">
                        <Button
                            type="primary"
                            icon={<EyeOutlined />}
                            style={{ marginLeft: '8px' }}
                            onClick={() => handleView(record)}
                        />
                        <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            style={{ marginLeft: '8px' }}
                            onClick={() => handleDelete(record)}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];


    const handleView = (record) => {
        navigate("/settings/casesWithDates/details")
        setSelectedRecord(record)
        dispatch(caseWithDateDetails(record))
    }


    return (
        <div>
            {
                isLoadingDelete && <Spinner />
            }
            <GenericModal
                visible={showModal}
                title=" "
                onCancel={onNoToggleModal}
                footer={false}
                width={'25rem'}
                content={<div className='p-4'>
                    <h5 className=' text-center'>Confirmation</h5>
                    <h6 className='text-center mt-4'>Are you sure, you want to remove this record?</h6>
                    <div className='d-flex justify-content-center mt-5'>
                        <Button
                            color='secondary'
                            onClick={onNoToggleModal}
                            style={{ width: '100px' }}
                        >No</Button>
                        <Button
                            type='primary'
                            className='ms-4'
                            style={{ width: '100px' }}
                            onClick={onYesDeleteRecord}
                        >Yes</Button>
                    </div>
                </div>}
            />
            <MyTable
                componentHeader={
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <h4>Cases</h4>
                        {/* <Button type="primary" onClick={showAddModal} style={{ marginBottom: '16px' }}>
                            Add
                        </Button> */}
                    </div>
                }
                columns={tableColumns}
                data={data}
                isLoading={isLoading}
            />

            <Modal
                title="Add"
                visible={isAddModalVisible}
                onOk={handleAddModalOk}
                onCancel={handleAddModalCancel}
            >
                <Form form={form}>
                    <Item label="Name" name="name" rules={[{ required: true, message: 'Please enter a name' }]}>
                        <Input />
                    </Item>
                    <Item label="Age" name="age" rules={[{ required: true, message: 'Please enter an age' }]}>
                        <Input type="number" />
                    </Item>
                    <Item label="Address" name="address" rules={[{ required: true, message: 'Please enter an address' }]}>
                        <Input />
                    </Item>
                    <Item label="Tags" name="tags">
                        <Input />
                    </Item>
                </Form>
            </Modal>

            <Modal
                title="Edit "
                visible={isEditModalVisible}
                onOk={handleEditModalOk}
                onCancel={handleEditModalCancel}
            >
                <Form form={form}>
                    <Item label="Name" name="name" rules={[{ required: true, message: 'Please enter a name' }]}>
                        <Input />
                    </Item>
                    <Item label="Age" name="age" rules={[{ required: true, message: 'Please enter an age' }]}>
                        <Input type="number" />
                    </Item>
                    <Item label="Address" name="address" rules={[{ required: true, message: 'Please enter an address' }]}>
                        <Input />
                    </Item>
                    <Item label="Tags" name="tags">
                        <Input />
                    </Item>
                </Form>
            </Modal>


        </div>
    );
};
