import React, { useEffect } from 'react';
import { Card, Row, Col, } from 'antd';
import { DashboardCounts } from './DashboardCounts';
import { BarChartComponent } from '../../components/BarChartComponent';
import { getCountCompletePendingCases } from '../../Utils/ApiManager';
import { Spinner } from '../../components/Spinner';
import { LineChartComponent } from '../../components/LineChartComponent';



export const Dashboard = () => {
    return (
        <div>
            <DashboardCounts />

            <Row gutter={16}>
                <Col span={12}>
                    <Card title="Counting Graph">
                        <BarChartComponent />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Cases Graph">
                        <LineChartComponent />
                    </Card>
                </Col>
            </Row>

            {/* <Row gutter={16}>
                <Col span={8}>
                    <Card style={{
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        borderRadius: '8px',
                        padding: '10px',
                    }}></Card>
                </Col>
                <Col span={8}>
                    <Card style={{
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        borderRadius: '8px',
                        padding: '10px',
                    }}></Card>
                </Col>
                <Col span={8}>
                    <Card style={{
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        borderRadius: '8px',
                        padding: '10px',
                    }}></Card>
                </Col>
            </Row> */}
        </div>
    );
};

