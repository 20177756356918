import React, { useEffect, useState } from 'react'
import { Tag, Button, Modal, Form, Input, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { deleteCase, getCases, getCategories } from '../../../Utils/ApiManager';
import MyTable from '../../../components/MyTable';
import moment from 'moment';
import { GenericModal } from '../../../components/GenericModal';
import { Spinner } from '../../../components/Spinner';

const { Item } = Form;


export const Cases = () => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const [form] = Form.useForm();


    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        setIsLoading(true)
        try {
            const response = await getCases()
            console.log("response", response?.data?.data)
            setData(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }


    const showAddModal = () => {
        setIsAddModalVisible(true);
        form.resetFields();
    };

    const handleAddModalOk = () => {
        form
            .validateFields()
            .then((values) => {
                setIsAddModalVisible(false);
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    const handleAddModalCancel = () => {
        setIsAddModalVisible(false);
    };

    const showEditModal = (record) => {
        setSelectedRecord(record);
        form.setFieldsValue(record);
        setIsEditModalVisible(true);
    };

    const handleEditModalOk = () => {
        form
            .validateFields()
            .then((values) => {
                setIsEditModalVisible(false);
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    const handleEditModalCancel = () => {
        setIsEditModalVisible(false);
    };

    const handleDelete = (record) => {
        setShowModal(true)
        setSelectedRecord(record);
    };

    const onNoToggleModal = () => {
        setShowModal(false)
        setSelectedRecord(null);
    }

    const onYesDeleteRecord = async () => {
        setIsLoadingDelete(true)
        try {
            await deleteCase(selectedRecord.caseId)
            getCases()
            setIsLoadingDelete(false)
            onNoToggleModal()
        } catch (error) {
            setIsLoadingDelete(false)
        }
    }

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Case ID',
            dataIndex: 'caseId',
            key: 'caseId',
        },
        {
            title: 'Case Status ID',
            dataIndex: 'caseStatusId',
            key: 'caseStatusId',
        },
        {
            title: 'Case Date Title',
            dataIndex: 'caseDateTitle',
            key: 'caseDateTitle',
        },
        {
            title: 'Date Description',
            dataIndex: 'dateDescription',
            key: 'dateDescription',
        },
        {
            title: 'Hearing Date',
            dataIndex: 'hearingDate',
            key: 'hearingDate',
            render: (hearingDate) => moment(hearingDate).local().format('lll'),
        },
        {
            title: 'Update Date',
            dataIndex: 'updateDate',
            key: 'updateDate',
            render: (updateDate) => moment(updateDate).local().format('lll'),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (createdDate) => moment(createdDate).local().format('lll'),
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
        },
        {
            title: 'Is Deleted',
            dataIndex: 'isDeleted',
            key: 'isDeleted',
            render: (isDeleted) => (isDeleted ? 'Yes' : 'No'),
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '15%',
            render: (_, record) => (
                <div>
                    <Button type="primary" onClick={() => null}>
                        Edit
                    </Button>

                    <Button type="danger" style={{ marginLeft: '8px' }}
                        onClick={() => handleDelete(record)}>
                        Delete
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div>
            {
                isLoadingDelete && <Spinner />
            }
            <GenericModal
                visible={showModal}
                title=" "
                onCancel={onNoToggleModal}
                footer={false}
                width={'25rem'}
                content={<div className='p-4'>
                    <h5 className=' text-center'>Confirmation</h5>
                    <h6 className='text-center mt-4'>Are you sure, you want to remove this record?</h6>
                    <div className='d-flex justify-content-center mt-5'>
                        <Button
                            color='secondary'
                            onClick={onNoToggleModal}
                            style={{ width: '100px' }}
                        >No</Button>
                        <Button
                            type='primary'
                            className='ms-4'
                            style={{ width: '100px' }}
                            onClick={onYesDeleteRecord}
                        >Yes</Button>
                    </div>
                </div>}
            />
            <MyTable
                componentHeader={
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <h4>Cases</h4>
                        <Button type="primary" onClick={showAddModal} style={{ marginBottom: '16px' }}>
                            Add
                        </Button>
                    </div>
                }
                columns={tableColumns}
                data={data}
                isLoading={isLoading}
            />

            <Modal
                title="Add"
                visible={isAddModalVisible}
                onOk={handleAddModalOk}
                onCancel={handleAddModalCancel}
            >
                <Form form={form}>
                    <Item label="Name" name="name" rules={[{ required: true, message: 'Please enter a name' }]}>
                        <Input />
                    </Item>
                    <Item label="Age" name="age" rules={[{ required: true, message: 'Please enter an age' }]}>
                        <Input type="number" />
                    </Item>
                    <Item label="Address" name="address" rules={[{ required: true, message: 'Please enter an address' }]}>
                        <Input />
                    </Item>
                    <Item label="Tags" name="tags">
                        <Input />
                    </Item>
                </Form>
            </Modal>

            <Modal
                title="Edit "
                visible={isEditModalVisible}
                onOk={handleEditModalOk}
                onCancel={handleEditModalCancel}
            >
                <Form form={form}>
                    <Item label="Name" name="name" rules={[{ required: true, message: 'Please enter a name' }]}>
                        <Input />
                    </Item>
                    <Item label="Age" name="age" rules={[{ required: true, message: 'Please enter an age' }]}>
                        <Input type="number" />
                    </Item>
                    <Item label="Address" name="address" rules={[{ required: true, message: 'Please enter an address' }]}>
                        <Input />
                    </Item>
                    <Item label="Tags" name="tags">
                        <Input />
                    </Item>
                </Form>
            </Modal>
        </div>
    );
};
