import React, { useEffect, useState } from 'react';
import { List, Card, Button, Tabs, Tag, } from 'antd';
import { Spinner } from '../../components/Spinner';
import { getAllNotifications, getUnreadNotifications, markAllNotificationsRead, readNotifications } from '../../Utils/ApiManager';
import moment from 'moment';

const { TabPane } = Tabs;

export const Notifications = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('all');

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true);
        try {
            const response = await getAllNotifications();
            console.log("response", response?.data?.data);
            setData(response?.data?.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };



    const markAsRead = async (notificationId) => {
        setIsLoading(true);
        try {
            const response = await readNotifications(notificationId);
            console.log("response", response?.data?.data);
            getData()
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };

    const markAllNotification = async () => {
        setIsLoading(true);
        try {
            const response = await markAllNotificationsRead();
            console.log("response", response?.data?.data);
            getData()
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };

    const getUnreadAllNotification = async () => {
        setIsLoading(true);
        try {
            const response = await getUnreadNotifications();
            console.log("response", response?.data?.data);
            setData(response?.data?.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("error", error);
        }
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
        if (key === 'unread') {
            getUnreadAllNotification()
        } else {
            getData()
        }
    };

    return (
        <div>
            {isLoading && <Spinner />}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                <Button type="primary" onClick={markAllNotification}>Mark all as read</Button>
            </div>
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="All" key="all">
                    <NotificationList data={data} markAsRead={markAsRead} />
                </TabPane>
                <TabPane tab="Unread" key="unread">
                    <NotificationList data={data} markAsRead={markAsRead} />
                </TabPane>
            </Tabs>


        </div>
    );
};

const NotificationList = ({ data, markAsRead }) => {
    return (
        <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={data}
            renderItem={item => (
                <List.Item>
                    <Card

                        bordered={false}
                        style={{
                            backgroundColor: item.isRead ? '#fff' : '#fafafa',
                            borderRadius: '8px',
                            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <div>


                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p style={{ marginBottom: 8 }}>
                                        {item.content}
                                    </p>
                                    {item.isRead ? 'Status: Read' : <Button
                                        type="primary"
                                        size="small"
                                        onClick={() => markAsRead(item.id)}
                                        style={{ marginTop: 12 }}
                                        disabled={item.isRead}
                                    >
                                        Mark as Read
                                    </Button>}
                                </div>
                                <div>
                                    <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                                        {!item.isRead && <Tag color="red">New</Tag>}
                                    </div>
                                    <p style={{ marginBottom: 4 }}>
                                        <>Date:</> {moment(item.createdDate).local().format('lll')}
                                    </p>
                                </div>
                            </div>
                        </div>


                    </Card>
                </List.Item>
            )}
        />
    );
};
