import React, { useState } from 'react';
import { Card, Form, Input, Button, Checkbox } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import logo from "../../media/logo.jpeg"
import { getAccountData, signInAccount } from '../../Utils/ApiManager';
import { Spinner } from '../../components/Spinner';
import { useDispatch } from 'react-redux';
import { loggedIn, loginToken, userData, } from '../../redux/actions';
import { toast } from 'react-toastify';
// import court from "../../media/courtroom.jpg"

export const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const onFinish = async (values) => {
        setIsLoading(true)
        try {
            const response = await signInAccount(values)
            console.log("response", response?.data?.data)
            if (response?.data?.httpStatusCode === 200 && response?.data?.data) {
                localStorage.setItem('LoginToken', JSON.stringify(response.data.data))
                localStorage.setItem('loggedIn', true)
                const userResponse = await getAccountData({
                    "userName": values.userName,
                    "password": values.password,
                    "isRemember": values.remember
                })
                dispatch(userData(userResponse?.data?.data))
                localStorage.setItem('userRole', userResponse.data.data.roles[0])
                localStorage.setItem('userData', JSON.stringify(userResponse.data.data))
                dispatch(loggedIn(true))
                dispatch(loginToken(response.data.data))
            } else {
                toast.error(response?.data?.data)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    };

    return (
        <>
            {
                isLoading && <Spinner />
            }
            <div
                style={{
                    // backgroundImage: `url(${court})`, // Corrected backgroundImage syntax
                    // backgroundPosition: 'center',
                    // backgroundSize: 'cover', // Ensures the image covers the entire area
                    // backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Card
                    style={{
                        marginBottom: '20px',
                        padding: '20px',
                        width: '90%',
                        maxWidth: '400px',
                    }}
                >
                    <div style={{ textAlign: 'center' }} >
                        <img src={logo} width={200} />
                        <h5 className='mt-4 mb-5'>Login to the Dashboard</h5>
                    </div>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        style={{ textAlign: 'left', width: '100%' }}
                    >
                        <Form.Item
                            label="Email"
                            name="userName"
                            rules={[
                                { required: true, message: 'Please enter your email' },
                                { type: 'email', message: 'Please enter a valid email' },
                            ]}
                            style={{ marginBottom: '10px' }}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>

                        <Form.Item
                            className='mb-1'
                            label="Password"
                            name="password"
                            rules={[
                                { required: true, message: 'Please enter your password' },
                            ]}
                        >
                            <Input.Password placeholder="Password" />
                        </Form.Item>
                        <Form.Item name="remember" valuePropName="checked">
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <span style={{ float: 'right' }}>
                            {/* <Link style={{ color: "grey" }} >Forgot Password?</Link> */}
                        </span>
                        <br />
                        <Form.Item>
                            <Button
                                className='mt-3'
                                style={{
                                    fontWeight: "500",
                                    color: "black",
                                }}
                                type="primary"
                                htmlType="submit"
                                block
                            >
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </>
    );
};
