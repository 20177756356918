import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Button, Spin, Divider, Modal, Row, Col, Avatar, Rate, Typography, List } from 'antd';
import { acceptRejectLawyerByAdmin, getLawyerDetailsByAdmin, getUserReview } from '../../Utils/ApiManager';
import { toastMessage } from '../../Utils/Utils';
import { useSelector } from 'react-redux';
import { Spinner } from '../../components/Spinner';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';


const { Text, Title } = Typography;


export const LawyerDetails = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const lawyerId = searchParams.get('lawyerId');

    const [lawyerDetails, setLawyerDetails] = useState(null);
    const [lawyerReviews, setLawyerReviews] = useState(null);
    const [reviewModal, setReviewModal] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [finalConfirmation, setFinalConfirmation] = useState(null);
    const [isAcceptCase, setIsAcceptCase] = useState(false);

    const navigate = useNavigate()
    const userData = useSelector(state => state.UserData)

    useEffect(() => {
        getLawyerDetailData()
    }, [lawyerId]);

    useEffect(() => {
        getLawyerReviews()
    }, [reviewModal]);

    const getLawyerDetailData = async () => {
        setIsLoading(true)
        try {
            const response = await getLawyerDetailsByAdmin(`?lawyerId=${lawyerId}`)
            setLawyerDetails(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }


    const getLawyerReviews = async () => {
        setIsLoading(true)
        try {
            const response = await getUserReview(`?UserId=${lawyerId}`)
            setLawyerReviews(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }



    const handleApprove = () => {
        setFinalConfirmation(true)
        setIsAcceptCase(true)
    };

    const handleReject = () => {
        setIsAcceptCase(false)
        setFinalConfirmation(true)
    };

    const handleSubmit = async () => {
        setIsLoading(true)
        try {
            await acceptRejectLawyerByAdmin({
                LoggedInUserId: userData?.userId,
                UserId: lawyerDetails?.lawyerProfile?.userId,
                IsApproved: isAcceptCase ? true : false
            })
            setIsLoading(false)
            navigate("/lawyers")
            setFinalConfirmation(false)
            toastMessage("Request completed successfully!", true)
        } catch (error) {
            setIsLoading(false)
            setFinalConfirmation(false)
            toastMessage("Getting error!", false)
        }
    }

    const finalConfirmationRequest = () => {
        return <Modal
            title="Request Confirmation"
            visible={finalConfirmation}
            onCancel={() => setFinalConfirmation(false)}
            footer={[
                <div
                    style={{
                        marginTop: '50px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                    <Button
                        style={{ width: '120px' }}
                        key="back"
                        onClick={() => setFinalConfirmation(false)}>
                        No
                    </Button>,
                    <Button
                        style={{ width: '120px' }}
                        type="primary"
                        onClick={handleSubmit}
                    >
                        Yes
                    </Button>
                </div>
            ]}>
            <div>

                <p >Do you really want to    {
                    isAcceptCase ? "accept " : "reject "}
                    this request?</p>
            </div>
        </Modal >
    }


    const getTotalRating = (reviews) => {
        if (reviews?.length === 0) return 0;
        const totalRating = reviews?.reduce((sum, review) => sum + review.rating, 0);
        return (totalRating / reviews?.length).toFixed(1);
    };


    return (
        <div style={{}}>
            {
                reviewModal &&
                <Modal
                    title="Reviews"
                    visible={reviewModal}
                    onOk={null}
                    onCancel={() => setReviewModal(false)}
                >
                    <List
                        itemLayout="vertical"
                        dataSource={lawyerReviews}
                        renderItem={review => (
                            <List.Item
                                key={review.reviewId}
                                style={{ padding: '15px', borderBottom: '1px solid #f0f0f0' }}
                            >
                                <List.Item.Meta
                                    avatar={<Avatar size={50} icon={<UserOutlined />} />}
                                    title={
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>
                                                <Text strong>{review.fullName}</Text>
                                                <Text style={{ fontStyle: 'italic', fontSize: '11px' }}> ({moment(review.createdDate).local().format("lll")})</Text>
                                            </div>

                                            <div>
                                                <Rate disabled value={Math.floor(review.rating)} />
                                                <Text style={{ marginLeft: '10px', fontSize: '14px' }}>{review.rating}</Text>
                                            </div>
                                        </div>
                                    }
                                    description={
                                        <div style={{ marginTop: '10px' }}>
                                            <Text>{review.reviewContent}</Text>
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Modal>
            }
            {
                isLoading && <Spinner />
            }
            {
                finalConfirmationRequest()
            }
            <h5 className='mb-4'>Lawyer Details</h5>

            {lawyerDetails && (
                <>
                    <Card >
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                            <Avatar
                                size={120}
                                icon={<UserOutlined />}
                                src={lawyerDetails?.lawyerProfile?.profilePic}
                                style={{ marginBottom: '15px' }}
                            />
                            <Title level={4} style={{ marginBottom: '10px' }}>
                                {lawyerDetails?.lawyerProfile?.fullName}
                            </Title>
                            <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Rate disabled value={Math.floor(getTotalRating(lawyerReviews))} />
                                <Text style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '500' }}>({getTotalRating(lawyerReviews)})</Text>
                            </div>                            <Row gutter={16} justify="center">
                                <Col>
                                    <Button
                                        type="default"
                                        size='small'
                                        onClick={() => setReviewModal(true)}
                                        style={{ width: '150px' }}
                                    >
                                        View Reviews
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <div style={{
                            display: 'flex', justifyContent: 'center'
                        }}>
                            <div style={{ width: '60%', }}>
                                <Row>
                                    <Col span={10}>
                                        <p><strong>Name:</strong> {lawyerDetails?.lawyerProfile?.fullName}</p>
                                        <p><strong>Email:</strong> {lawyerDetails?.lawyerProfile?.email}</p>
                                        <p><strong>CNIC Number:</strong> {lawyerDetails?.lawyerProfile?.cnicNo}</p>
                                        <p><strong>Contact Number:</strong> {lawyerDetails?.lawyerProfile?.contactNumber}</p>
                                        <p><strong>Current Address:</strong> {lawyerDetails?.lawyerProfile?.currAddress}</p>
                                        <p><strong>Permanent Address:</strong> {lawyerDetails?.lawyerProfile?.permAddress}</p>
                                    </Col>
                                    <Col span={2}></Col>
                                    <Col span={12}>
                                        <p><strong>Office Address:</strong> {lawyerDetails?.lawyerProfile?.officeAddress}</p>
                                        <p><strong>Bar Council ID:</strong> {lawyerDetails?.lawyerProfile?.barCouncilId}</p>
                                        <p><strong>Bar Council Number:</strong> {lawyerDetails?.lawyerProfile?.barCouncilNo}</p>
                                        <p><strong>Enrollment Date:</strong> {lawyerDetails?.lawyerProfile?.enrollmentDate}</p>
                                        <p><strong>Profile Description:</strong> {lawyerDetails?.lawyerProfile?.profileDescription}</p>
                                        <p><strong>Country Code:</strong> {lawyerDetails?.lawyerProfile?.countryCode}</p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Card>
                    <br />
                    <Row>
                        <Col span={11}>
                            <Card style={{ height: '150px' }} >
                                <p><strong>Qualifications:</strong></p>
                                {lawyerDetails?.lawyerQualifications.map(qualification => (
                                    <p key={qualification.qualificationId}>{qualification.degreeName} - {qualification.instituteName}</p>
                                ))}
                            </Card>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Card style={{ height: '150px' }} >
                                <p><strong>Expertise:</strong></p>
                                {lawyerDetails?.lawyerExperties.map(expertise => (
                                    <div key={expertise.id}>
                                        <p >{expertise.categoryId} - {expertise.categoryName ?? ""}</p>

                                    </div>
                                ))}
                            </Card>
                        </Col>
                    </Row>
                    <Divider />
                    <div style={{
                        display: 'flex', justifyContent: 'end'
                    }}>
                        <Button style={{ marginRight: '10px', width: '150px' }} type="danger" onClick={handleReject}>Reject</Button>
                        <Button type="primary" onClick={handleApprove} style={{ width: '150px' }}>Accept</Button>
                    </div>
                </>

            )
            }
        </div >
    );
};

